import React from 'react';
import { Bill3rdPartyEInvoiceForm } from './Bill3rdPartyEInvoiceForm';

const bill3rdPartyEInvoiceRoutes = [
	{
		props: {
			exact: true,
			path: `/menux/outlet/:outletID/bill-qr/:billID`,
		},
		component: <Bill3rdPartyEInvoiceForm />,
	},
];

export default bill3rdPartyEInvoiceRoutes;
