import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CalcType,
	DiscountType,
	GetDiscountDocument,
	useCheckOdInUseLazyQuery,
	useCheckOidInUseLazyQuery,
	useCreateDiscountMutation,
	useDeleteDiscountMutation,
	useGetDiscountQuery,
	useGetOutletQuery,
	useUpdateDiscountMutation,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

const sampleData = [
	{
		ID: '1',
		name: 'Member20',
		designation: 'Member special discount',
		percent: '60%',
	},
	{
		ID: '2',
		name: 'MCO Special',
		designation: 'MCO special discount',
		percent: '100.00',
	},
	{
		ID: '3',
		name: 'April Special',
		designation: 'April month special',
		percent: '30%',
	},
];

export const DiscountTypeList = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const [openDialog, setOpenDialog] = useState(false);
	const [openInactiveDialog, setOpenInactiveDialog] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const { outletID } = useParams<Record<string, any>>();
	const discountID = 1;
	const {
		data: { getDiscount } = { getDiscount: [] },
		called: discountCalled,
		loading: discountLoading,
	} = useGetDiscountQuery({
		fetchPolicy: 'network-only',
		variables: { outletID },
	});

	const [
		submitDiscount,
		{ data: createdDiscountData, loading: submitDiscountLoading },
	] = useCreateDiscountMutation({
		fetchPolicy: 'no-cache',
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		deleteDiscount,
		{ loading: deleteDiscountLoading },
	] = useDeleteDiscountMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const [
		loadCheckODInUse,
		{ data: { checkODInUse } = { checkODInUse: [] } },
	] = useCheckOdInUseLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadCheckOIDInUse,
		{ data: { checkOIDInUse } = { checkOIDInUse: [] } },
	] = useCheckOidInUseLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		updateDiscount,
		{ called: updateDiscountCalled, loading: updateDiscountLoading },
	] = useUpdateDiscountMutation({
		onError: error => {},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const submitActiveInactive = () => {
		updateDiscount({
			variables: {
				input: {
					ID: menu?.ID,
					outletID: outletID,
					code: menu?.obj?.code,
					description: menu?.obj?.description,
					amount: parseFloat(menu?.obj?.amount),
					calcType: menu?.obj?.calcType,
					discountType: menu?.obj?.discountType,
					isActive: menu?.obj?.isActive === true ? false : true,
				},
			},
			refetchQueries: [{ query: GetDiscountDocument, variables: { outletID } }],
		});
	};

	const [openDeleteDialog, setDeleteDialog] = useState(false);

	const morevertList = [
		{
			name: 'Edit',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Delete',
			action: () => {
				handleClose();
			},
		},
	];

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	// useEffect(
	// 	() => {
	// 		if (
	// 			(!discountCalled && data?.getDiscount === undefined) ||
	// 			data?.getDiscount?.length === 0
	// 		) {
	// 			history.push(
	// 				`/menu/outlet-app/outlet-setting/${outletID}/discount-type/add`,
	// 			);
	// 		} else {
	// 			history.push({
	// 				pathname: `/menu/outlet-app/outlet-setting/${outletID}/discount-type`,
	// 			});
	// 		}
	// 	},
	// 	// [],
	// 	[data?.getDiscount],
	// );

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const [rerender, setRender] = useState(false);
	const discountSearch = JSON.parse(localStorage.getItem('searchFilter'));

	useEffect(() => {
		if (getDiscount && getDiscount?.length > 0) {
			console.log('lalu');
			setOriginalListing(getDiscount);
		}
	}, [getDiscount]);

	useEffect(() => {
		if (
			!!discountSearch?.discountList &&
			getDiscount?.length > 0 &&
			!discountLoading
		) {
			setRender(true);
			handleSearch(`'"${discountSearch?.discountList ?? ''}"`, [
				'code',
				'description',
			]);
		}
	}, [discountLoading, rerender, getDiscount]);

	console.log('filteredList', filteredList);

	console.log(menu?.ID, 'menu?.ID');
	const temp = getDiscount?.find(el => el?.ID === menu?.ID);

	console.log('getDiscount >>>', getDiscount);

	useEffect(() => {
		if (menu?.ID) {
			loadCheckODInUse({
				variables: {
					discountID: menu?.ID,
					outletID: outletID,
				},
			});

			loadCheckOIDInUse({
				variables: {
					discountID: menu?.ID,
					outletID: outletID,
				},
			});
		}
	}, [menu?.ID]);

	return (
		<>
			{discountLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitDiscountLoading && <Loading />}
			{deleteDiscountLoading && <Loading />}

			<MainHeader
				onClick={() => {
					localStorage.removeItem('searchFilter');
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					});
				}}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Discount Type', current: true },
				]}
			/>

			<SearchHeader
				title="Discount Type List"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['code', 'description']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							discountList: e.target.value ?? '',
						}),
					);
				}}
				fixed
				search
				isDefaultValue={!!discountSearch?.discountList ?? false}
				defaultValue={discountSearch?.discountList ?? ''}
				onCloseAction={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							discountList: '',
						}),
					);
					handleSearch('', []);
				}}
				clickText
			/>

			<ContentWrapper float search>
				<List className="core-list">
					{!discountLoading &&
					!outletLoading &&
					(getDiscount === undefined || getDiscount?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add discount type to continue"
						/>
					) : (
						filteredList?.map((el, index) => {
							return (
								<ListItem key={index} disabled={el?.isActive === false}>
									<ListItemText
										primary={
											<>
												{/* <span className="desc"> */}
												<span className="xsTitle flex-space">{el.code}</span>
												<span className="xsTitle" style={{ color: '#ff9800' }}>
													{' '}
													{el.calcType === CalcType.Amount
														? `${amtStr(el.amount)}`
														: `${el.amount}%`}
												</span>
												{/* </span> */}
											</>
										}
										secondary={
											<div className="desc">
												<span className="highlight-text">
													{`${
														el?.discountType === DiscountType.OfficerCheck
															? 'Officer Check'
															: toProperCase(el?.discountType)
													}`}
												</span>
												<span className="desc">{` | ${el.description}`}</span>
											</div>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => {
												handleClick(e, el.ID, index, el);
											}}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
						})
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem>
						<span
							onClick={() =>
								history.push({
									pathname: `/menu/outlet-app/outlet-setting/${outletID}/discount-type/${menu.ID}/edit`,
									state: menu.obj,
								})
							}
						>
							Edit
						</span>
					</MenuItem>
					<MenuItem
						disabled={checkODInUse === true || checkOIDInUse === true}
						className={
							checkODInUse === true || checkOIDInUse === true
								? 'grayed-out'
								: ''
						}
						onClick={() => {
							setOpenInactiveDialog(true);
						}}
					>
						<span className="">
							{menu?.obj?.isActive === true ? 'Inactive' : 'Active'}
						</span>
					</MenuItem>
					<MenuItem
						disabled={temp?.discountCheckingInUseInfo === 'ACTIVE'}
						className={
							temp?.discountCheckingInUseInfo === 'ACTIVE' ? 'grayed-out' : ''
						}
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>
			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.code}
				deleteMutation={deleteDiscount}
				refetchDocument={GetDiscountDocument}
			/>

			<CommonDialogV2
				fullWidth
				open={openInactiveDialog}
				onClose={() => setOpenInactiveDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title color-primary-orange">
											{menu?.obj?.isActive === true ? 'Inactive' : 'Active'}
											{` ${menu?.obj?.code}`}
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div>
							<span className="mdDesc">
								{menu?.obj?.isActive === true
									? 'Confirm inactive?'
									: 'Confirm activate?'}
							</span>
						</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenInactiveDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenInactiveDialog(false);
										submitActiveInactive();
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>

			<FloatButton
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/discount-type/add`,
					)
				}
			/>
		</>
	);
};
