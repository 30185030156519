import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Card, CardContent, TextField } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CommonYupValidation } from 'helpers/yup';
import { SystemMsgs } from 'helpers/SystemMsg';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { AddressAutoCompleteComponent } from '@ifca-root/react-component/src/components/Address/AddressAutoCompleteComponent';
import { useAddressAutoComplete } from 'helpers/hooks/useAddressAutoComplete';
import { countryAlphaCodes } from 'utils/countryAlphaCodes';
import { countryCurrencyCodes } from 'utils/countryCurrencyCodes';
import {
	GetBillDocument,
	useGetCountryNameLazyQuery,
	useGetStateNameLazyQuery,
	useUpdateBuyerInformationMutation,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useHistory } from 'react-router';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { DynamicFooter } from 'components/Footer/DynamicFooter';

interface BillUserEInvoiceFormProps {
	name: string;
	email: string;
	contactNo: string;
	address: string;
	country: string;
	state: string;
	city: string;
	postcode: string;
	identityType: string;
	identityNumber: string;
	tin: string;
	regNo: string;
}

export const BuyerDetailsForm = props => {
	const { data, isBuyerDetails, setIsBuyerDetails } = props;
	const mode = 'edit';
	const editData = data;
	const history = useHistory();

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		loadCountry,
		{
			loading: getCountryLoading,
			data: { getCountryName } = { getCountryName: [] },
		},
	] = useGetCountryNameLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadState,
		{ loading: getStateLoading, data: { getStateName } = { getStateName: [] } },
	] = useGetStateNameLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		updateBuyerInformation,
		{ loading: updateBuyerInformationLoading },
	] = useUpdateBuyerInformationMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setIsBuyerDetails(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const {
		getCountry,
		getStatebyCountry,
		getCitybyState,
		getPostcodebyCity,
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		query,
		autoCompleteRef,
		state,
		setQuery,
		dispatch,
		initializeEditValue,
	} = useAddressAutoComplete();

	const [country, setCountry] = useState('');
	const [newState, setState] = useState('');
	const [city, setCity] = useState('');
	const [newPostcode, setPostcode] = useState('');
	const [countryCode, setCountryCode] = useState('my');
	const [ogAddr, setOGAddr] = useState(null);
	const [addrValue, setAddress] = useState(null);

	useEffect(() => {
		if (getCountry?.length > 0 && mode === 'edit') {
			let currCountry = getCountry?.find(v => {
				return (
					v?.name?.toLowerCase() === editData?.address?.country?.toLowerCase()
				);
			});

			handleCountryOnChange(currCountry);
		}
	}, [getCountry, mode]);

	useEffect(() => {
		const regex = new RegExp(`\\b(${country})\\b`, 'g');
		const countryCodes = Object.keys(countryAlphaCodes);
		if (country !== '') {
			countryCodes.forEach(code => {
				if (regex.test(countryAlphaCodes?.[code])) {
					setCountryCode(code.toLowerCase() ?? 'my');
				}
			});
			let alphaCode;
			const currencyCodes = Object.keys(countryCurrencyCodes);
			currencyCodes.forEach(code => {
				if (regex.test(countryCurrencyCodes?.[code])) {
					alphaCode = code;
				}
			});
		}
	}, [country]);

	useEffect(() => {
		if (mode === 'edit' && editData) {
			let array_of_address = [
				editData?.einvoice_buyer_address,
				editData?.einvoice_buyer_city,
				getStateName[0]?.name || editData?.einvoice_buyer_state,
				getCountryName[0]?.name || editData?.einvoice_buyer_country,
			];
			setQuery(array_of_address.join(', '));
			setOGAddr(array_of_address.join(', '));
			setAddress(array_of_address.slice(0));
			initializeEditValue({
				address: editData?.einvoice_buyer_address,
				country: getCountryName[0]?.name || editData?.einvoice_buyer_country,
				state: getStateName[0]?.name || editData?.einvoice_buyer_state,
				city: editData?.einvoice_buyer_city,
				postCode: editData?.einvoice_buyer_post_code,
			});
		}
	}, [mode, editData, getCountryName[0]?.name, getStateName[0]?.name]);

	console.log(
		'state >>>',
		getStateName[0]?.name,
		editData?.einvoice_buyer_state,
	);
	console.log(
		'country >>>',
		getCountryName[0]?.name,
		editData?.einvoice_buyer_country,
	);

	const billUserEInvoiceSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		email: CommonYupValidation.requireField(SystemMsgs.email()),
		contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
		regNo: CommonYupValidation.requireField('Registration Number is required'),
		tin: CommonYupValidation.requireField('TIN is required'),
	});

	const {
		handleSubmit,
		register,
		errors,
		control,
		getValues,
		watch,
		formState: { isSubmitting, isSubmitted, dirtyFields, isDirty },
	} = useForm<BillUserEInvoiceFormProps>({
		defaultValues: {
			address: '',
			country: '',
			state: '',
			city: '',
			postcode: '',
		},
		mode: 'onSubmit',
		resolver: yupResolver(billUserEInvoiceSchema),
	});

	useEffect(() => {
		if (editData?.einvoice_buyer_country) {
			loadCountry({
				variables: {
					eInvCode: editData?.einvoice_buyer_country,
				},
			});
		}
	}, [editData?.einvoice_buyer_country]);

	useEffect(() => {
		if (getCountryName[0]?.ID) {
			loadState({
				variables: {
					countryID: getCountryName[0]?.ID,
					eInvCode: editData?.einvoice_buyer_state,
				},
			});
		}
	}, [getCountryName[0]?.ID]);

	const onSubmit = () => {
		updateBuyerInformation({
			variables: {
				buyerInformation: {
					ID: data?.ID,
					name: watch('name'),
					email: watch('email'),
					contactNo: watch('contactNo'),
					tin: watch('tin'),
					regNo: watch('regNo'),
					address: {
						address: state?.address,
						country: state?.country?.name,
						state: state?.state?.name,
						city: state?.city?.name,
						postCode: state?.postcode?.postcode,
					},
				},
			},
			refetchQueries: [
				{
					query: GetBillDocument,
					variables: {
						ID: data?.ID,
					},
				},
			],
		});
	};

	return (
		<>
			{getStateLoading && <Loading />}
			{getCountryLoading && <Loading />}

			<CardContents
				style={{
					overflowY: 'auto',
					maxHeight: 'calc(100vh - 120px)',
					paddingRight: '10px',
				}}
			>
				<Card
					style={{
						backgroundColor: '#f0f0f0',
						boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
						borderRadius: '8px',
						width: '100%',
						marginTop: '1%',
						marginBottom: '4%',
					}}
				>
					<CardContent>
						<div
							style={{
								fontSize: '12px',
								fontWeight: 400,
								lineHeight: '14px',
								fontStyle: 'italic',
								marginTop: '5px',
							}}
						>
							Please complete the following form :
						</div>

						<Controller
							as={TextField}
							name="name"
							fullWidth
							margin="normal"
							label="Name"
							required
							defaultValue={data?.einvoice_buyer_name}
							helperText={errors?.name?.message}
							error={errors?.name ? true : false}
							control={control}
							ref={register}
							autoComplete="nope"
						></Controller>

						<Controller
							as={TextField}
							name="email"
							label="Email Address"
							autoComplete="off"
							multiline={true}
							required
							defaultValue={data?.einvoice_buyer_email}
							fullWidth
							ref={register}
							control={control}
							margin="dense"
							helperText={errors?.email?.message}
							error={errors?.email ? true : false}
						></Controller>

						<Controller
							as={
								<PhoneNumInput
									country={'my'}
									specialLabel="Contact No."
									fullWidth
								/>
							}
							name="contactNo"
							type="tel"
							marginTop
							marginBottom
							required
							defaultValue={data?.einvoice_buyer_contact_no}
							margin="normal"
							control={control}
							ref={register}
							helperText={errors?.contactNo?.message}
							error={errors?.contactNo ? true : false}
							autoComplete="off"
						></Controller>

						<AddressAutoCompleteComponent
							getCountry={getCountry}
							getStatebyCountry={getStatebyCountry}
							getCitybyState={getCitybyState}
							getPostcodebyCity={getPostcodebyCity}
							handleCountryOnChange={handleCountryOnChange}
							handleStateOnChange={handleStateOnChange}
							handleCityOnChange={handleCityOnChange}
							handlePostCodeOnChange={handlePostCodeOnChange}
							handleGoogleOnChange={handleGoogleOnChange}
							autoCompleteRef={autoCompleteRef}
							state={state}
							country={country}
							setCountry={setCountry}
							setState={setState}
							setCity={setCity}
							newState={newState}
							newPostcode={newPostcode}
							setPostcode={setPostcode}
							city={city}
							formType={mode}
							isSubmitted={isSubmitted}
							editData={editData}
						/>

						<Controller
							as={TextField}
							name="tin"
							label="Tax Identification Number (TIN)"
							autoComplete="off"
							multiline={true}
							required
							fullWidth
							ref={register}
							control={control}
							defaultValue={data?.einvoice_buyer_tin}
							margin="dense"
							helperText={errors?.tin?.message}
							error={errors?.tin ? true : false}
						></Controller>

						<Controller
							as={TextField}
							name="regNo"
							label="SST Registration Number"
							autoComplete="off"
							multiline={true}
							required
							fullWidth
							defaultValue={data?.einvoice_buyer_tax_regno}
							ref={register}
							control={control}
							margin="dense"
							helperText={errors?.regNo?.message}
							error={errors?.regNo ? true : false}
						></Controller>
					</CardContent>
				</Card>
			</CardContents>

			<DynamicFooter
				overlap
				options={[
					{
						name: 'Cancel',
						onClick: () => {
							setIsBuyerDetails(false);
						},
						color: 'primary',
					},
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						color: 'primary',
					},
				]}
			/>
		</>
	);
};
