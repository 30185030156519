import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetHomeSummaryQuery,
	useGetOutletLazyQuery,
	useGetOutletQuery,
	useGetUserLogoLazyQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OutletIcon } from '../../../assets';
import { useLazyQuery } from '@apollo/react-hooks';
import { AccountClient } from 'ProductsClient';
import {
	COUNTRY,
	GET_ACCOUNT_DETAIL,
	GET_CURRENCY,
	GET_PACKAGE_BY_ACCOUNT,
	GET_SUBSCRIPTION_ENTITY_CONTROL,
} from 'containers/AccountModule/AccountQuery';
import localForage from 'localforage';

const useStyles = makeStyles({
	spaced: {
		['& > *']: {
			marginRight: '0.5rem',
		},
		['& :not(span)']: {
			width: '0.8rem',
			height: '0.8rem',
		},
	},
});

export const OutletList = () => {
	const classes = useStyles();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletSearch = JSON.parse(localStorage.getItem('searchFilter'));
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const [rerender, setRender] = useState(false);

	const outletID = localStorage.getItem('latestOutlet');

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const { handlePermDisabled } = usePermissionChecker();

	const {
		data: { getOutlet } = { getOutlet: [] },
		loading: outletLoading,
		called: outletCalled,
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const history = useHistory();

	const tableCount = (dataArr: any) => {
		let totalTableCount = 0;
		dataArr?.map((el: any) => {
			totalTableCount += el?.seatCount;
		});

		return totalTableCount;
	};

	// Drawer Toggler
	const handleDrawer = () => {
		localStorage.removeItem('searchFilter');
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	useEffect(() => {
		if (getOutlet && getOutlet?.length > 0 && !outletLoading) {
			setOriginalListing(getOutlet);
		}
	}, [getOutlet, outletLoading]);
	useEffect(() => {
		if (!!outletSearch?.outletList && getOutlet?.length > 0 && !outletLoading) {
			setRender(true);
			handleSearch(`'"${outletSearch?.outletList ?? ''}"`, ['name']);
		}
	}, [outletLoading, rerender, getOutlet]);

	function customSort(a: any, b: any) {
		const nameA = a.name;
		const nameB = b.name;

		// Check if the name contains brackets
		const hasBracketsA = /\(.*\)/.test(nameA);
		const hasBracketsB = /\(.*\)/.test(nameB);

		// Compare and return based on whether brackets are present
		if (hasBracketsA && hasBracketsB) {
			// Both have brackets, compare alphabetically
			return nameA.localeCompare(nameB);
		} else if (hasBracketsA) {
			return 1; // Only A has brackets, move it to the bottom
		} else if (hasBracketsB) {
			return -1; // Only B has brackets, move it to the bottom
		} else {
			// Neither has brackets, compare alphabetically
			return nameA.localeCompare(nameB);
		}
	}

	return (
		<>
			{outletLoading && <Loading />}

			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[{ name: 'Outlets', current: true }]}
			/>

			<SearchHeader
				title="Outlet List"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['name']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletList: e.target.value ?? '',
							outletSubmenu: outletSearch?.outletSubmenu ?? '',
							menuItemSearch: outletSearch?.menuItemSearch ?? '',
						}),
					);
				}}
				fixed
				search
				isDefaultValue={!!outletSearch?.outletList ?? false}
				defaultValue={outletSearch?.outletList ?? ''}
				onCloseAction={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletList: '',
							outletSubmenu: outletSearch?.outletSubmenu ?? '',
							menuItemSearch: outletSearch?.menuItemSearch ?? '',
						}),
					);
					handleSearch('', []);
				}}
				clickText
			/>

			<ContentWrapper float search>
				<List className="core-list">
					{!outletLoading &&
					(getOutlet === undefined || getOutlet?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add an outlet to continue"
						/>
					) : (
						filteredList?.sort(customSort)?.map((el: any, index) => (
							<ListItem
								onClick={() =>
									history.push(`/menu/outlet-app/outlet-setting/${el.ID}`)
								}
								key={el?.ID}
							>
								<ListItemIcon>
									{el?.logo?.fileURL ? (
										<img
											className="icon-svg"
											style={{
												height: '40px',
												width: '40px',
												borderRadius: '30px',
												marginRight: '10px',
											}}
											src={el?.logo?.fileURL}
										/>
									) : (
										<img
											className="icon-svg"
											style={{
												height: '25px',
												width: '40px',
												borderRadius: '30px',
												marginRight: '10px',
											}}
											src={OutletIcon}
										/>
									)}
								</ListItemIcon>

								<ListItemText
									primary={
										<div>
											<span className="xsTitle">{el?.name}</span>
										</div>
									}
									secondary={
										<div className="desc">
											<span className="desc">{'Phone No: '}</span>
											<span className="highlight-text text-separator">
												{el?.contactNo}
											</span>
											<span className="desc">
												{'Tables: '}
												<span className="highlight-text text-separator">
													{el?.tablesInfo?.count}
												</span>
											</span>
											<span className="desc">
												{'Capacity: '}
												<span className="highlight-text">
													{el?.tablesInfo?.capacity}
												</span>
											</span>
										</div>
									}
								/>
								{/* payment-reminder */}
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={() =>
											history.push(`/menu/outlet-app/outlet-setting/${el.ID}`)
										}
									>
										<KeyboardArrowRight />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<FloatButton
					onClick={() =>
						history.push(`/menu/outlet-app/outlet-setting/outlet-profile/add`)
					}
				/>
				{/* {getOutlet?.length < user?.maxOutlet && (
					<FloatButton
						onClick={() =>
							history.push(`/menu/outlet-app/outlet-setting/outlet-profile/add`)
						}
						disabled={handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.OutletSettingCreate,
						})}
					/>
				)} */}
			</ContentWrapper>
		</>
	);
};
