import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { Button, IconButton, TextField } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
	FacebookIcon,
	FacebookShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from 'react-share';
interface ShareType {
	simple: boolean;
	setSimple: any;
	title: string;
	shareTitle: string;
	URL: string;
	emailShare?: string;
	setSimpleEmail?: any;
	isReceipt?: boolean;
	phoneNumber?: string;
	openPhoneNum?: boolean;
	setOpenPhoneNum?: any;
	setOpenEmail?: any;
}
const dateFormat = require('dateformat');

export const htmlConverter = ({ formContent, name }) => {
	var blob = new Blob([formContent], { type: 'text/html' });
	blob['name'] = name + '.html';
	blob['lastModified'] = Date.UTC(
		dateFormat(new Date(), 'yyyy'),
		dateFormat(new Date(), 'mm'),
		dateFormat(new Date(), 'dd'),
	);
	blob['lastModifiedDate'] = new Date();

	return blob;
};

const ShareDialog = (props: ShareType) => {
	const {
		simple,
		setSimple,
		title,
		URL,
		shareTitle,
		emailShare,
		setSimpleEmail,
		isReceipt,
		phoneNumber,
		setOpenPhoneNum,
		setOpenEmail,
	} = props;

	return (
		<CommonDialog
			fullWidth={true}
			open={simple}
			onClose={() => setSimple(false)}
			sections={{
				header: {
					dynamic: (
						<div className="">
							<div className="dialog-dynamic-content">
								<div className="session">
									<div className="title">{title}</div>
								</div>
							</div>
						</div>
					),
				},
				body: () => (
					<>
						<div
							className="content-container"
							style={{ justifyContent: 'space-evenly' }}
						>
							{isReceipt === true ? (
								<>
									<IconButton
										edge="end"
										aria-label="delete"
										onClick={() =>
											phoneNumber === undefined ||
											phoneNumber === null ||
											phoneNumber === ''
												? (setOpenPhoneNum(true), setSimple(false))
												: window.open(
														`https://wa.me/${phoneNumber}?text=${encodeURIComponent(
															`${shareTitle} ${URL}`,
														)}`,
												  )
										}
									>
										<WhatsappIcon
											style={{ color: '#4FCE5D', marginRight: '5px' }}
											size={40}
											round={true}
										/>
									</IconButton>
								</>
							) : (
								<>
									<WhatsappShareButton title={shareTitle} url={URL}>
										<WhatsappIcon
											style={{ marginRight: '5px' }}
											size={40}
											round={true}
										/>
									</WhatsappShareButton>
								</>
							)}

							<TelegramShareButton title={shareTitle} url={URL}>
								<TelegramIcon
									style={{ marginRight: '5px' }}
									size={40}
									round={true}
								/>
							</TelegramShareButton>

							<Button
								onClick={() =>
									phoneNumber === undefined ||
									phoneNumber === null ||
									phoneNumber === ''
										? (setOpenEmail(true), setSimple(false))
										: setSimpleEmail(true)
								}
							>
								<MailOutlineIcon style={{ marginRight: '5px', fontSize: 40 }} />
							</Button>
						</div>
					</>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => setSimple(false),
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);
};

export const PhoneNumDialog = props => {
	const { open, setOpen, shareTitle, URL } = props;

	const [phoneNum, setPhoneNum] = useState('');

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Whatsapp Phone Number</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<PhoneNumInput
								name="phoneNumber"
								country={'my'}
								specialLabel="Phone Number"
								autoComplete="off"
								multiline
								fullWidth
								margin="normal"
								onChange={e => setPhoneNum(e)}
							></PhoneNumInput>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setPhoneNum('');
										setOpen(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpen(false);
										window.open(
											`https://wa.me/${phoneNum}?text=${encodeURIComponent(
												`${shareTitle} ${URL}`,
											)}`,
										);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export const EmailDialog = props => {
	const { open, setOpen, setSimpleEmail, setEmail } = props;

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Email</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<TextField
								name="email"
								label={'Email Address'}
								autoComplete="off"
								multiline={true}
								fullWidth
								margin="dense"
								onChange={e => setEmail(e.target.value)}
							/>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setEmail('');
										setOpen(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpen(false);
										setSimpleEmail(true);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

const ConvertFormToURL = ({ formContent, name }) => {
	var contentType = 'text/html';
	const blob = new Blob([formContent], { type: contentType });
	blob['name'] = name + '.html';
	blob['lastModified'] = Date.UTC(
		dateFormat(new Date(), 'yyyy'),
		dateFormat(new Date(), 'mm'),
		dateFormat(new Date(), 'dd'),
	);
	blob['lastModifiedDate'] = new Date();

	return blob;
};
export { ShareDialog as default, ConvertFormToURL };
