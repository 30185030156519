import React from 'react';
import { ConsolidatedEInvoiceListing } from './ConsolidatedEInvoiceListing';
import { ConsolidatedEInvoiceForm } from './ConsolidatedEInvoiceForm';
import { ConsolidatedEInvoiceView } from './ConsolidatedEInvoiceView';

const consolidatedeInvoiceRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/consolidate-e-inovoice`,
		},
		component: <ConsolidatedEInvoiceListing />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/consolidate-e-inovoice/add`,
		},
		component: <ConsolidatedEInvoiceForm />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/consolidate-e-inovoice/view/:consolidatedEInvoiceID`,
		},
		component: <ConsolidatedEInvoiceView mode="view" />,
	},
];

export default consolidatedeInvoiceRoutes;
