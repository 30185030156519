import DefaultAvatar from '@ifca-root/react-component/src/assets/images/default-avatar.png';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { Fab, TextField, Switch, MenuItem } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import {
	GetCompanyListingDocument,
	useCreateCompanyMutation,
	useCurrencyListingQuery,
	useGetCompanyListingByIdLazyQuery,
	useGetEInvoiceSubQuery,
	useUpdateCompanyMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import { AddressAutoCompleteComponent } from '@ifca-root/react-component/src/components/Address/AddressAutoCompleteComponent';
import { useAddressAutoComplete } from 'helpers/hooks/useAddressAutoComplete';
import { countryCurrencyCodes } from 'utils/countryCurrencyCodes';
import { countryAlphaCodes } from 'utils/countryAlphaCodes';
import DateFnsUtils from '@date-io/date-fns';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { CommonYupValidation } from 'helpers/yup';

interface CompanyListingFormProps {
	code: string;
	name: string;
	companyRegNo: string;
	groupGstRegNo: string;
	gstNo: string;
	sstNo: string;
	ttxNo: string;
	description: string;
	email: string;
	officeTel: string;
	tin: string;
	baseCurrencyId: string;
	eSign: string;
	eSignExpiryDate: Date;
	isEInvIntegrated: boolean;
	eInvStartDate: string;
	eInvEndDate: string;
	eInvClientID: string;
	eInvClientSecret: string;
	eInvClientSecret2: string;
	address: string;
	country: string;
	state: string;
	city: string;
	postcode: string;
}

export const CompanyForm = (props: any) => {
	const { mode } = props;
	let history = useHistory();
	let location = useLocation();
	const { [`companyID`]: companyID } = useParams<Record<string, any>>();
	const menuData: any = location?.state;
	const editData = menuData?.company as any;
	const [previewFile, setPreview] = useState('');

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);
	let user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [
		loadCompany,
		{
			called: companyCalled,
			loading: companyLoading,
			data: { getCompanyListingByID } = { getCompanyListingByID: [] },
		},
	] = useGetCompanyListingByIdLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCompanyListingByID }) => {
			setPreview(getCompanyListingByID[0]?.imageUrl);
		},
	});

	const {
		data: { CurrencyListing } = { CurrencyListing: [] },
		loading: CurrencyListingLoading,
	} = useCurrencyListingQuery({
		fetchPolicy: 'no-cache',
	});

	const {
		data: { getEInvoiceSub } = { getEInvoiceSub: [] },
		loading: getEInvoiceSubLoading,
		refetch,
	} = useGetEInvoiceSubQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	useEffect(() => {
		if (mode == 'edit') {
			loadCompany({
				variables: {
					accountID: user?.accountID,
					ID: companyID,
				},
			});
		}
	}, [mode]);

	const {
		fileMap,
		previewMap,
		handleSingleUploadMapping,
	} = useUploadAttachment();

	const previewMapArray: any = previewMap;

	useEffect(() => {
		if (previewMap !== null) {
			setPreview(previewMapArray.company);
		}
	}, [previewMap]);

	const {
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		query,
		autoCompleteRef,
		state,
		setQuery,
		dispatch,
		initializeEditValue,
	} = useAddressAutoComplete();

	useEffect(() => {
		if (getCountry?.length > 0 && mode === 'edit') {
			let currCountry = getCountry?.find(v => {
				return (
					v?.name?.toLowerCase() === editData?.address?.country?.toLowerCase()
				);
			});

			handleCountryOnChange(currCountry);
		}
	}, [getCountry, mode]);

	const [ogAddr, setOGAddr] = useState(null);
	const [addrValue, setAddress] = useState(null);
	const [country, setCountry] = useState('');
	const [newState, setState] = useState('');
	const [city, setCity] = useState('');
	const [newPostcode, setPostcode] = useState('');
	const [countryCode, setCountryCode] = useState('my');

	useEffect(() => {
		const regex = new RegExp(`\\b(${country})\\b`, 'g');
		const countryCodes = Object.keys(countryAlphaCodes);
		if (country !== '') {
			countryCodes.forEach(code => {
				if (regex.test(countryAlphaCodes?.[code])) {
					setCountryCode(code.toLowerCase() ?? 'my');
				}
			});
			// find currency code first
			let alphaCode;
			const currencyCodes = Object.keys(countryCurrencyCodes);
			currencyCodes.forEach(code => {
				if (regex.test(countryCurrencyCodes?.[code])) {
					alphaCode = code;
				}
			});
		}
	}, [country]);

	useEffect(() => {
		if (mode === 'edit' && editData) {
			let array_of_address = [
				editData?.address?.address,
				editData?.address?.city,
				editData?.address?.state,
				editData?.address?.country,
			];
			setQuery(array_of_address.join(', '));
			setOGAddr(array_of_address.join(', '));
			setAddress(array_of_address.slice(0));
			initializeEditValue({
				address: editData?.address?.address,
				country: editData?.address?.country,
				state: editData?.address?.state,
				city: editData?.address?.city,
				postCode: editData?.address?.postCode,
			});
		}
	}, [mode, editData]);

	const [isEInvoice, setIsEInvoice] = useState(
		editData?.isEInvIntegrated ?? false,
	);

	console.log(isEInvoice, 'isEInvoice');

	const yupSchema = yup.object().shape({
		code: CommonYupValidation.requireField(SystemMsgs.code()),
		name: CommonYupValidation.requireField(SystemMsgs.companyName()),
		companyRegNo: CommonYupValidation.requireField(SystemMsgs.regNo()),
		description: CommonYupValidation.requireField(SystemMsgs.description()),
		baseCurrencyId: CommonYupValidation.requireField(
			'Base Currency is required',
		),
		officeTel: CommonYupValidation.requireField('Office Tel No. is required'),
		email: CommonYupValidation.requireField(SystemMsgs.email()),
		tin:
			isEInvoice === true || editData?.isEInvIntegrated === true
				? CommonYupValidation.requireField('TIN is required')
				: null,
		// eInvStartDate:
		// 	isEInvoice === true || editData?.isEInvIntegrated === true
		// 		? CommonYupValidation.requireField('Start Date is required')
		// 		: null,
		// eInvEndDate:
		// 	isEInvoice === true ?? editData?.isEInvIntegrated === true
		// 		? CommonYupValidation.requireField('End Date is required')
		// 		: null,
		eSign:
			isEInvoice === true ?? editData?.isEInvIntegrated === true
				? CommonYupValidation.requireField('Digital Signature is required')
				: null,
		eSignExpiryDate:
			isEInvoice === true ?? editData?.isEInvIntegrated === true
				? CommonYupValidation.requireField(
						'Digital Signature Expiry Date is required',
				  )
				: null,
		eInvClientID:
			isEInvoice === true ?? editData?.isEInvIntegrated === true
				? CommonYupValidation.requireField('Client ID is required')
				: null,
		eInvClientSecret:
			isEInvoice === true ?? editData?.isEInvIntegrated === true
				? CommonYupValidation.requireField('Secret Key 1 is required')
				: null,
		eInvClientSecret2:
			isEInvoice === true ?? editData?.isEInvIntegrated === true
				? CommonYupValidation.requireField('Secret Key 2 is required')
				: null,
	});

	const {
		handleSubmit,
		register,
		errors,
		control,
		formState: { isSubmitting, isSubmitted, dirtyFields, isDirty },
	} = useForm<CompanyListingFormProps>({
		defaultValues: {
			name: mode === 'add' ? '' : editData?.name,
			code: mode === 'add' ? '' : editData?.code,
			companyRegNo: mode === 'add' ? '' : editData?.companyRegNo,
			groupGstRegNo: mode === 'add' ? '' : editData?.groupGstRegNo,
			gstNo: mode === 'add' ? '' : editData?.gstNo,
			email: mode === 'add' ? '' : editData?.email,
			sstNo: mode === 'add' ? '' : editData?.sstNo,
			ttxNo: mode === 'add' ? '' : editData?.ttxNo,
			description: mode === 'add' ? '' : editData?.description,
			baseCurrencyId: mode === 'add' ? '' : editData?.baseCurrencyId,
			address: mode === 'add' ? '' : editData?.address.address,
			country: mode === 'add' ? '' : editData?.address.country,
			state: mode === 'add' ? '' : editData?.address.state,
			city: mode === 'add' ? '' : editData?.address.city,
			postcode: mode === 'add' ? '' : editData?.address.postCode,
			officeTel: mode === 'add' ? '' : editData?.officeTel,
			tin: mode === 'add' ? '' : editData?.tin,
			eSign: mode === 'add' ? '' : editData?.eSign,
			eSignExpiryDate: mode === 'add' ? '' : editData?.eSignExpiryDate,
			isEInvIntegrated: mode === 'add' ? '' : editData?.isEInvIntegrated,
			eInvStartDate: mode === 'add' ? '' : editData?.eInvStartDate,
			eInvEndDate: mode === 'add' ? '' : editData?.eInvEndDate,
			eInvClientID: mode === 'add' ? '' : editData?.eInvClientID,
			eInvClientSecret: mode === 'add' ? '' : editData?.eInvClientSecret,
			eInvClientSecret2: mode === 'add' ? '' : editData?.eInvClientSecret2,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const [
		createCompany,
		{ data: createCompanyData, loading: createCompanyLoading },
	] = useCreateCompanyMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			// history.push(`/subcontract/${consubconID}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/common-setting/company-list`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 1000);
		},
	});

	const [
		updateCompany,
		{ data: updateCompanyData, loading: updateCompanyLoading },
	] = useUpdateCompanyMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconID}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/common-setting/company-list`,
					state: { success: true, msgMode: 'update' },
				});
				//history.goBack();
			}, 1000);
		},
	});

	const refetchQueries = [
		{
			query: GetCompanyListingDocument,
			variables: {
				accountID: user?.accountID,
			},
		},
	];

	const onSubmit = data => {
		console?.log(data, mode, 'mode1');

		const finalFiles_company_logo = addingDescToFiles(
			'company',
			fileMap['company'],
		);
		let input: any = {
			...(mode === 'edit' ? { id: companyID } : null),
			accountId: user?.accountID,
			code: data?.code,
			name: data?.name,
			companyRegNo: data?.companyRegNo,
			description: data?.description,
			groupGstRegNo: data?.groupGstRegNo,
			gstNo: data?.gstNo,
			sstNo: data?.sstNo,
			ttxNo: data?.ttxNo,
			baseCurrencyId: data?.baseCurrencyId,
			officeTel: data?.officeTel,
			email: data?.email,
			tin: data?.tin,
			address: {
				address: state.address,
				state: state.state.name,
				city: state.city.name,
				country: state.country.name,
				postCode: state.postcode.postcode,
			},
			eSign: data?.eSign,
			eSignExpiryDate: data.eSignExpiryDate,
			isEInvIntegrated: isEInvoice,
			eInvStartDate: dates?.eInvStartDate,
			eInvEndDate: dates?.eInvEndDate,
			eInvClientID: data.eInvClientID,
			eInvClientSecret: data.eInvClientSecret,
			eInvClientSecret2: data.eInvClientSecret2,
			logoFiles: finalFiles_company_logo,
		};

		console.log('input', typeof data.eInvStartDate);
		if (mode === 'add') {
			console?.log(mode, mode, 'mode2');

			createCompany({
				variables: {
					companyInput: input,
				},
				refetchQueries: refetchQueries,
			});
		} else {
			updateCompany({
				variables: {
					companyInput: input,
				},
				refetchQueries: refetchQueries,
			});
		}
	};

	const addingDescToFiles = (description: string, file: any) => {
		let temp = file;
		for (const v in temp) {
			temp[v].description = description;
		}

		return temp;
	};

	const [openExitDialog, setOpenExitDialog] = useState(false);

	const defaultEndDates = new Date();
	defaultEndDates.setFullYear(defaultEndDates.getFullYear() + 10);

	const [dates, setDates] = useState({
		eInvStartDate: editData?.eInvStartDate
			? new Date(editData.eInvStartDate)
			: new Date(),
		eInvEndDate: editData?.eInvEndDate
			? new Date(editData.eInvEndDate)
			: new Date(defaultEndDates),
	});

	const handleStartDateChange = (date: Date | null) => {
		if (date) {
			const newEndDate = new Date(date);
			newEndDate.setFullYear(newEndDate.getFullYear() + 10);
			setDates({
				eInvStartDate: date,
				eInvEndDate: new Date(newEndDate),
			});
		}
	};

	const handleEndDateChange = (date: Date | null) => {
		if (date) {
			const newStartDate = new Date(date);
			newStartDate.setFullYear(newStartDate.getFullYear() - 10);
			setDates({
				eInvStartDate: new Date(newStartDate),
				eInvEndDate: date,
			});
		}
	};

	const [eSignExpiryDates, setESignExpiryDates] = useState({
		eSignExpiryDate: editData?.eSignExpiryDate
			? new Date(editData.eSignExpiryDate)
			: null,
	});

	const handleESignExpiryDatesChange = (date: Date | null) => {
		if (date) {
			setESignExpiryDates({
				eSignExpiryDate: date,
			});
		}
	};

	console.log(editData, eSignExpiryDates, 'editData');

	return (
		<>
			<MainHeader
				mainBtn="close"
				onClick={() => setOpenExitDialog(true)}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{
						name: 'Company Listing',
						current: true,
					},
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>

			<ContentWrapper float onlyContentScrollable>
				<CardContents devFullWidth={true}>
					{previewFile ? (
						<img src={previewFile} alt="" className="upload-user-avatar" />
					) : (
						<img src={DefaultAvatar} alt="" className="upload-user-avatar" />
					)}
					<Fab
						color="primary"
						size="small"
						aria-label="add"
						className="float-img-upload "
					>
						<label htmlFor="icon-button-file"></label>
						<Publish />
					</Fab>

					<input
						type="file"
						hidden
						id="icon-button-file"
						onChange={e => handleSingleUploadMapping(e, 'company')}
					/>

					<Controller
						as={TextField}
						name="code"
						fullWidth
						label="Company Code"
						required
						helperText={errors?.code?.message}
						error={errors?.name ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="name"
						fullWidth
						label="Company Name"
						required
						helperText={errors?.name?.message}
						error={errors?.name ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="companyRegNo"
						fullWidth
						className={'left'}
						label="Registration No"
						required
						helperText={errors?.companyRegNo?.message}
						error={errors?.companyRegNo ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="groupGstRegNo"
						fullWidth
						className={'right'}
						label="Group GST Registration No"
						// required
						helperText={errors?.groupGstRegNo?.message}
						error={errors?.groupGstRegNo ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="gstNo"
						fullWidth
						label="GST Registration No"
						className={'left'}
						// required
						helperText={errors?.gstNo?.message}
						error={errors?.gstNo ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="sstNo"
						fullWidth
						className={'right'}
						label="SST Registration No"
						// required
						helperText={errors?.sstNo?.message}
						error={errors?.sstNo ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="ttxNo"
						fullWidth
						label="TTX Registration No."
						required
						multiline
						helperText={errors?.ttxNo?.message}
						error={errors?.ttxNo ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={TextField}
						name="description"
						fullWidth
						label="Company Description"
						required
						multiline
						helperText={errors?.description?.message}
						error={errors?.description ? true : false}
						control={control}
						ref={register}
					/>

					<Controller
						as={
							<TextField
								name="baseCurrencyId"
								defaultValue={editData?.baseCurrencyId}
							>
								{CurrencyListing?.map(x => (
									<MenuItem key={x?.id} value={x?.id}>
										{x?.code}
									</MenuItem>
								))}
							</TextField>
						}
						name="baseCurrencyId"
						margin="normal"
						label="Base Currency"
						fullWidth
						autoComplete="off"
						multiline={true}
						select
						required
						control={control}
						ref={register}
					></Controller>

					{/* <div style={{ width: '50%' }}> */}
					<Controller
						as={
							<PhoneNumInput
								country={'my'}
								specialLabel="Office Tel No."
								fullWidth
								required
							/>
						}
						name="officeTel"
						// className={'left'}
						control={control}
						ref={register}
						required
						helperText={errors?.officeTel?.message}
						error={errors?.officeTel ? true : false}
					/>
					{/* </div> */}

					<Controller
						as={TextField}
						name="email"
						label="Email"
						fullWidth
						// className={'right'}
						required
						helperText={errors?.email?.message}
						error={errors?.email ? true : false}
						control={control}
						ref={register}
					/>

					<AddressAutoCompleteComponent
						getCountry={getCountry}
						getStatebyCountry={getStatebyCountry}
						getCitybyState={getCitybyState}
						getPostcodebyCity={getPostcodebyCity}
						handleCountryOnChange={handleCountryOnChange}
						handleStateOnChange={handleStateOnChange}
						handleCityOnChange={handleCityOnChange}
						handlePostCodeOnChange={handlePostCodeOnChange}
						handleGoogleOnChange={handleGoogleOnChange}
						autoCompleteRef={autoCompleteRef}
						state={state}
						country={country}
						setCountry={setCountry}
						setState={setState}
						setCity={setCity}
						newState={newState}
						newPostcode={newPostcode}
						setPostcode={setPostcode}
						city={city}
						formType={mode}
						isSubmitted={isSubmitted}
						editData={editData}
					/>

					<div
						className="full"
						style={{
							justifyContent: 'space-between',
							display: 'flex',
						}}
					>
						<span className="flex-space" style={{ alignSelf: 'center' }}>
							Register e-Invoice
						</span>

						<Switch
							name="isEInvIntegrated"
							checked={isEInvoice}
							defaultChecked={editData?.isEInvIntegrated}
							disabled={getEInvoiceSub[0]?.ID ? false : true}
							onChange={e => {
								if (isEInvoice === false) {
									setIsEInvoice(true);
								} else if (isEInvoice === true) {
									setIsEInvoice(false);
								}
							}}
							color="primary"
						/>
					</div>

					{(isEInvoice === true ?? editData?.isEInvIntegrated === true) && (
						<>
							<Controller
								as={TextField}
								name="tin"
								fullWidth
								label="TIN"
								required={
									isEInvoice === true ?? editData?.isEInvIntegrated === true
										? true
										: false
								}
								helperText={errors?.tin?.message}
								error={errors?.tin ? true : false}
								control={control}
								ref={register}
							/>

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<Controller
									name="eInvStartDate"
									control={control}
									render={({ onChange }) => (
										<KeyboardDatePicker
											label="Start Date"
											format="dd MMM yyyy"
											required={
												isEInvoice === true ??
												editData?.isEInvIntegrated === true
											}
											value={dates?.eInvStartDate}
											onChange={date => {
												handleStartDateChange(date);
												onChange(date);
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											className="left"
										/>
									)}
								/>

								<Controller
									name="eInvEndDate"
									control={control}
									render={({ onChange }) => (
										<KeyboardDatePicker
											label="End Date"
											format="dd MMM yyyy"
											required={
												isEInvoice === true ??
												editData?.isEInvIntegrated === true
											}
											value={dates?.eInvEndDate}
											onChange={date => {
												handleEndDateChange(date);
												onChange(date);
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											className="right"
										/>
									)}
								/>
							</MuiPickersUtilsProvider>

							<Controller
								as={TextField}
								name="eInvClientID"
								fullWidth
								label="Client ID"
								control={control}
								ref={register}
								required={
									isEInvoice === true ?? editData?.isEInvIntegrated === true
										? true
										: false
								}
								helperText={errors?.eInvClientID?.message}
								error={errors?.eInvClientID ? true : false}
							/>

							<Controller
								as={TextField}
								name="eInvClientSecret"
								fullWidth
								label="Secret Key 1"
								control={control}
								// className="left"
								ref={register}
								required={
									isEInvoice === true ?? editData?.isEInvIntegrated === true
										? true
										: false
								}
								helperText={errors?.eInvClientSecret?.message}
								error={errors?.eInvClientSecret ? true : false}
							/>

							<Controller
								as={TextField}
								name="eInvClientSecret2"
								fullWidth
								label="Secret Key 2"
								control={control}
								// className="right"
								ref={register}
								required={
									isEInvoice === true ?? editData?.isEInvIntegrated === true
										? true
										: false
								}
								helperText={errors?.eInvClientSecret2?.message}
								error={errors?.eInvClientSecret2 ? true : false}
							/>
							<Controller
								as={TextField}
								name="eSign"
								fullWidth
								label="Digital Signature"
								required={
									isEInvoice === true ?? editData?.isEInvIntegrated === true
										? true
										: false
								}
								helperText={errors?.eSign?.message}
								error={errors?.eSign ? true : false}
								control={control}
								ref={register}
							/>

							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								{/* <Controller
									as={KeyboardDatePicker}
									name="eSignExpiryDate"
									label="Digital Signature Expiry Date"
									format="dd MMM yyyy"
									required={
										isEInvoice === true ?? editData?.isEInvIntegrated === true
											? true
											: false
									}
									value={editData?.eSignExpiryDate || new Date()}
									onChange={(Date | null) => {}}
									control={control}
									ref={register}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
								/> */}

								<Controller
									name="eSignExpiryDate"
									control={control}
									render={({ onChange }) => (
										<KeyboardDatePicker
											label="Digital Signature Expiry Date"
											format="dd MMM yyyy"
											required={
												isEInvoice === true ??
												editData?.isEInvIntegrated === true
											}
											value={eSignExpiryDates?.eSignExpiryDate}
											onChange={(date: Date | null) => {
												handleESignExpiryDatesChange(date);
												onChange(date);
											}}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
										/>
									)}
								/>
							</MuiPickersUtilsProvider>
						</>
					)}
				</CardContents>
			</ContentWrapper>

			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/>

			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/common-setting/company-list`}
			/>
		</>
	);
};
