import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetRoleDocument,
	PosPermission,
	useDeleteRolePermissionMutation,
	useGetRoleQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
	RestaurantPerm,
	KitchenPerm,
	// BusinessInsightPerm,
	// GeneralSettingPerm,
	// OutletSettingPerm,
	// ReservationPerm,
} from './RolesTree';
import { DuplicateRoleDialog } from './DuplicateRoleDialog';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

const sampleData = [
	{
		ID: '1',
		name: 'General Manager',
		access: 20,
	},
	{
		ID: '2',
		name: 'Project Manager',
		access: 30,
	},
	{
		ID: '3',
		name: 'Accountant',
		access: 45,
	},
];

export const RolesList = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const rolesSearch = JSON.parse(localStorage.getItem('searchFilter'));
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const [rerender, setRender] = useState(false);
	const [duplicateDialog, setDuplicateDialog] = useState(false);
	const [roleID, setRoleID] = useState(null);
	const [roleName, setRoleName] = useState('');
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const { handlePermDisabled } = usePermissionChecker();

	const outletID = localStorage.getItem('latestOutlet');

	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const {
		called,
		loading: rolesLoading,
		error,
		data: { getRole } = { getRole: [] },
	} = useGetRoleQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		// setHeaderProps({
		// 	mainBtn: 'back',
		// 	onClick: () => history.goBack(),
		// 	smTitle: user?.contactPerson?.name,
		// 	title: posOutlet?.name as string,
		// 	routeSegments: [{ name: 'Menu Settings' }],
		// 	rightRouteSegments: [{ name: 'Major Group' }],
		// });
	}, []);

	const moreVertList = [
		{
			name: 'View',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Inactive',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Delete',
			action: () => {
				handleClose();
			},
		},
	];

	const [msg, setMsg] = useState<boolean>(false);

	const [open, setOpen] = useState<boolean>(false);

	// const [deleteRolePermission] = useDeleteRolePermissionMutation({
	// 	onError: (error) => {
	// 		console.log('ERROR', error);
	// 	},
	// 	onCompleted: (data) => {},
	// });

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		deleteRolePermission,
		{ loading: mutationUpdateLoading, error: mutationUpdateError },
	] = useDeleteRolePermissionMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			if (data) {
				handleClose();

				setTimeout(() => {
					setMsg(false);
				}, 3000);
			}
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const handleDelete = () => {
		deleteRolePermission({
			variables: {
				roleID: menu.ID,
			},
			refetchQueries: [
				{
					query: GetRoleDocument,
					variables: {},
				},
			],
		}).then(() => setOpen(false));
		setMsg(true);
	};

	const countAccessible = [
		{ restaurantTree: RestaurantPerm },
		{ kitchenTree: KitchenPerm },
		// { generalSettingTree: GeneralSettingPerm },
		// { outletSettingTree: OutletSettingPerm },
		// { reservationTree: ReservationPerm },
		// { businessInsightTree: BusinessInsightPerm },
	];

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const handleDrawer = () => {
		localStorage.removeItem('searchFilter');
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	useEffect(() => {
		if (getRole && getRole?.length > 0 && !rolesLoading) {
			setOriginalListing(getRole);
		}
	}, [getRole, rolesLoading]);

	useEffect(() => {
		if (!!rolesSearch?.rolesList && getRole?.length > 0 && !rolesLoading) {
			setRender(true);
			handleSearch(`'"${rolesSearch?.rolesList ?? ''}"`, ['name']);
		}
	}, [rolesLoading, rerender, getRole]);

	const existingRole = getRole?.map(r => r?.name);

	return (
		<>
			{rolesLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/general-setting/user-access`,
					})
				}
				// onClick={handleDrawer}
				mainBtn="back"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Access Security' },
					{ name: 'Roles', current: true },
				]}
				// rightRouteSegments={[
				// ]}
			/>

			<SearchHeader
				title="Roles List"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['name']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							rolesList: e.target.value ?? '',
							rolesSubmenu: rolesSearch?.rolesSubmenu ?? '',
							menuItemSearch: rolesSearch?.menuItemSearch ?? '',
						}),
					);
				}}
				fixed
				search
				isDefaultValue={!!rolesSearch?.rolesList ?? false}
				defaultValue={rolesSearch?.outletList ?? ''}
				onCloseAction={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							rolesList: '',
							rolesSubmenu: rolesSearch?.rolesSubmenu ?? '',
							menuItemSearch: rolesSearch?.menuItemSearch ?? '',
						}),
					);
					handleSearch('', []);
				}}
				clickText
			/>
			<ContentWrapper search float>
				<List className="core-list">
					{!rolesLoading && (getRole === undefined || getRole?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Add a new record now."
						/>
					) : (
						filteredList?.map((el, index) => (
							<ListItem key={index}>
								<ListItemText
									primary={
										<>
											<span className="xsTitle flex-space">{el.name}</span>
										</>
									}
									secondary={
										<>
											<span className="xxTitle" style={{ color: '#ff9800' }}>
												{
													// el.rolePerm?.filter(function(rolePerm) {
													// 	return (
													// 		rolePerm.permName !==
													// 			'GENERAL_SETTING_ACCESS_SECURITY_USERS______CREATE' &&
													// 		rolePerm.permName !==
													// 			'GENERAL_SETTING_ACCESS_SECURITY_USERS______VIEW' &&
													// 		rolePerm.permName !==
													// 			'GENERAL_SETTING_ACCESS_SECURITY_USERS______UPDATE'
													// 	);
													// }).length
													el?.rolePerm?.length
												}
											</span>{' '}
											<span className="desc">
												{/* /{countAccessible.length} Accessible */}
												{'/135 Accessible'}
											</span>{' '}
										</>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="delete"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e =>
											handleClick(e, el.ID, index, getRole[index], el)
										}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>

							// <PersonListing
							// 	el={el}
							// 	index={index}
							// 	handleClick={handleClick}
							// 	TLInfo={el?.name}
							// 	customBL={true}
							// 	BLInfo={
							// 		<span className="desc highlight-text">
							// 			<span style={{ color: '#ff9800' }}>{'80'}</span>
							// 			{`/80 Accessible`}
							// 		</span>
							// 	}
							// 	isEmpty={getRole?.length === 0}
							// 	moreVertNeeded={true}
							// 	moreVertList={moreVertList}
							// 	anchorEl={anchorEl}
							// 	handleClose={handleClose}
							// />
						))
					)}
				</List>

				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem>
						<span
							onClick={() =>
								history.push({
									pathname: `/menu/outlet-app/general-setting/user-access/role/${menu?.ID}/detail`,
									state: menu.obj,
								})
							}
						>
							View
						</span>
					</MenuItem>
					<MenuItem
						disabled={handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.GeneralSettingAccessSecurityRolesUpdate,
						})}
						onClick={() =>
							history.push({
								pathname: `/menu/outlet-app/general-setting/user-access/role/${menu?.ID}/edit`,
								state: menu.obj,
							})
						}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDuplicateDialog(true);
							setRoleID(menu?.obj?.ID);
							setRoleName(menu?.obj?.name);
							handleClose();
						}}
						// disabled={handlePermDisabled({
						//   permEnum: IfcaxPermission.AccessSecurityRoleUpdate,
						// })}
					>
						Duplicate
					</MenuItem>
					<MenuItem
						disabled={handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.GeneralSettingAccessSecurityRolesDelete,
						})}
						onClick={() => setOpen(true)}

						// onClick={() => {
						// 	setDeleteDialog(true);
						// }}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>

				<DuplicateRoleDialog
					roleID={roleID}
					roleName={roleName}
					duplicateDialog={duplicateDialog}
					setDuplicateDialog={setDuplicateDialog}
					existingRole={existingRole}
				/>
				<CommonDialog
					fullWidth={true}
					open={open}
					// deleteMutation={deleteRolePermission}
					// refetchDocument={GetRoleDocument}
					onClose={() => setOpen(false)}
					sections={{
						header: {
							title: `Delete Role`,
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">Delete Role</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<div className="content-wrap full">
								<div className="desc">Role Name</div>
								<div className="xsTitle">{menu?.obj?.name}</div>
							</div>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setOpen(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => handleDelete(),
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/>
				<FloatButton
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAccessSecurityRolesCreate,
					})}
					onClick={() =>
						history.push(
							`/menu/outlet-app/general-setting/user-access/role/add`,
						)
					}
				/>
			</ContentWrapper>
		</>
	);
};
