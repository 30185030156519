import React from 'react';
import { EInvoicePolicyForm } from './EInvoicePolicyForm';

const eInvoicePolicyRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/e-invoice-policy`,
		},
		component: <EInvoicePolicyForm />,
	},
];

export default eInvoicePolicyRoutes;
