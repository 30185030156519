import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid } from '@material-ui/core';
import { useGetTaxSummaryQuery } from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';

export const SSTSummaryComponent = props => {
	const { outletID, orderID, accountID } = props;

	const {
		loading: getTaxSummaryLoading,
		data: { getTaxSummary } = { getTaxSummary: [] },
	} = useGetTaxSummaryQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			orderID: orderID,
			accountID: accountID,
		},
	});

	const totalAmount = getTaxSummary?.reduce((a, b) => {
		return a + b?.amount;
	}, 0);

	const totalTaxAmount = getTaxSummary?.reduce((a, b) => {
		return a + b?.taxAmount;
	}, 0);

	return (
		<>
			{getTaxSummaryLoading && <Loading />}

			<div
				className={'xsTitle'}
				style={{ fontSize: '16px', paddingTop: '5px', paddingBottom: '10px' }}
			>
				{`SST Summary`}{' '}
			</div>

			<Grid container>
				<Grid item xs={4}>
					<span
						className="xsTitle text-noflow"
						style={{ fontSize: '16px' }}
					>{`Tax Cd`}</span>
				</Grid>

				<Grid item xs={5} style={{ textAlign: 'right' }}>
					<span
						className="xsTitle text-noflow"
						style={{ fontSize: '16px' }}
					>{`Amt Excl SST(RM)`}</span>
				</Grid>

				<Grid item xs={3} style={{ textAlign: 'right' }}>
					<span
						className="xsTitle text-noflow"
						style={{ fontSize: '16px' }}
					>{`Tax(RM)`}</span>
				</Grid>
			</Grid>

			{getTaxSummary?.map(x => {
				return (
					<>
						<Grid container>
							<Grid item xs={4}>
								<span
									className="mdDesc text-noflow"
									style={{ fontSize: '16px' }}
								>
									{x?.code}
								</span>
							</Grid>

							<Grid item xs={5} style={{ textAlign: 'right' }}>
								<span
									className="mdDesc text-noflow"
									style={{ fontSize: '16px' }}
								>
									{amtStr(x?.amount)}
								</span>
							</Grid>

							<Grid item xs={3} style={{ textAlign: 'right' }}>
								<span
									className="mdDesc text-noflow"
									style={{ fontSize: '16px' }}
								>
									{amtStr(x?.taxAmount)}
								</span>
							</Grid>
						</Grid>
					</>
				);
			})}

			<Grid container>
				<Grid item xs={4}>
					<span
						className="xsTitle text-noflow"
						style={{ fontSize: '16px' }}
					>{`Total`}</span>
				</Grid>

				<Grid item xs={5} style={{ textAlign: 'right' }}>
					<span className="xsTitle text-noflow" style={{ fontSize: '16px' }}>
						{amtStr(totalAmount)}
					</span>
				</Grid>

				<Grid item xs={3} style={{ textAlign: 'right' }}>
					<span className="xsTitle text-noflow" style={{ fontSize: '16px' }}>
						{amtStr(totalTaxAmount)}
					</span>
				</Grid>
			</Grid>
		</>
	);
};
