import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import { Bill3rdPartyEInvoiceLayout } from '@ifca-root/react-component/src/components/Bill3rdPartyEInvoiceLayout/Bill3rdPartyEInvoiceLayout';

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Background from 'assets/images/HR-Background.jpg';
import Logo from 'assets/images/MenuX-Logo.svg';
import {
	useGet3rdPartyEInvoiceRequestDetailQuery,
	useGetBillAndOutletForNotAuthQuery,
	useGetBillQuery,
	useGetBillWithDateQuery,
	useGetOutletQuery,
	useSubmitDocumentEInvoice3rdpartyMutation,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import theme from '@ifca-root/react-component/src/assets/theme';
import {
	Button,
	Card,
	CardContent,
	Grid,
	MenuItem,
	TextField,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { CommonYupValidation } from 'helpers/yup';
import { SystemMsgs } from 'helpers/SystemMsg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { countryAlphaCodes } from 'utils/countryAlphaCodes';
import { countryCurrencyCodes } from 'utils/countryCurrencyCodes';
import { currencyCodeSymbols } from 'utils/currencyCodeSymbols';
import PhoneInput from 'react-phone-input-2';
import { convertDate24Hrs, formatDate } from 'helpers/formatDate';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { useAddressAutoComplete } from 'helpers/hooks/useAddressAutoComplete';
import { AddressAutoCompleteComponent } from '@ifca-root/react-component/src/components/Address/AddressAutoCompleteComponent';

interface BillUserEInvoiceFormProps {
	name: string;
	email: string;
	contactNo: string;
	address: string;
	country: string;
	state: string;
	city: string;
	postcode: string;
	identityType: string;
	identityNumber: string;
	tin: string;
	sstRegNo: string;
}
export const Bill3rdPartyEInvoiceForm = (props: any) => {
	let history = useHistory();
	let location = useLocation();
	const { mode } = props;
	const menuData: any = location?.state;
	const editData = menuData?.company as any;

	const {
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		query,
		autoCompleteRef,
		state,
		setQuery,
		dispatch,
		initializeEditValue,
	} = useAddressAutoComplete();

	useEffect(() => {
		if (getCountry?.length > 0 && mode === 'edit') {
			let currCountry = getCountry?.find(v => {
				return (
					v?.name?.toLowerCase() === editData?.address?.country?.toLowerCase()
				);
			});

			handleCountryOnChange(currCountry);
		}
	}, [getCountry, mode]);

	const [ogAddr, setOGAddr] = useState(null);
	const [addrValue, setAddress] = useState(null);
	const [country, setCountry] = useState('');
	const [newState, setState] = useState('');
	const [city, setCity] = useState('');
	const [newPostcode, setPostcode] = useState('');
	const [countryCode, setCountryCode] = useState('my');
	const [identityPrefix, setIdentityPrefix] = useState('');
	const [identityNumber, setIdentityNumber] = useState('');
	useEffect(() => {
		const regex = new RegExp(`\\b(${country})\\b`, 'g');
		const countryCodes = Object.keys(countryAlphaCodes);
		if (country !== '') {
			countryCodes.forEach(code => {
				if (regex.test(countryAlphaCodes?.[code])) {
					setCountryCode(code.toLowerCase() ?? 'my');
				}
			});
			// find currency code first
			let alphaCode;
			const currencyCodes = Object.keys(countryCurrencyCodes);
			currencyCodes.forEach(code => {
				if (regex.test(countryCurrencyCodes?.[code])) {
					alphaCode = code;
				}
			});
		}
	}, [country]);

	useEffect(() => {
		if (mode === 'edit' && editData) {
			let array_of_address = [
				editData?.address?.address,
				editData?.address?.city,
				editData?.address?.state,
				editData?.address?.country,
			];
			setQuery(array_of_address.join(', '));
			setOGAddr(array_of_address.join(', '));
			setAddress(array_of_address.slice(0));
			initializeEditValue({
				address: editData?.address?.address,
				country: editData?.address?.country,
				state: editData?.address?.state,
				city: editData?.address?.city,
				postCode: editData?.address?.postCode,
			});
		}
	}, [mode, editData]);

	const identityTypeData = [
		{ name: 'Identity Card (NRIC)', code: 'NRIC' },
		{ name: 'Passport', code: 'PASSPORT' },
		{ name: 'Business Registration Number', code: 'BRN' },
		{ name: 'Military Card', code: 'ARMY' },
	];

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const appCode: any = localStorage.getItem('appCode');

	const { outletID, billID } = useParams<any>(); // Keeping it in case you need these later
	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		called: billAndOutletForNotAuthCalled,
		loading: billAndOutletForNotAuthLoading,
		error: billAndOutletForNotAuthError,
		data: { getBillAndOutletForNotAuth } = { getBillAndOutletForNotAuth: [] },
	} = useGetBillAndOutletForNotAuthQuery({
		variables: { outletID: outletID, billID: billID },
		fetchPolicy: 'network-only',
		onCompleted: ({ getBillAndOutletForNotAuth }) => {},
	});

	const {
		called: bill3rdPartyEInvoiceRequestDetailCalled,
		loading: bill3rdPartyEInvoiceRequestDetailLoading,
		error: bill3rdPartyEInvoiceRequestDetailError,
		data: { get3rdPartyEInvoiceRequestDetail } = {
			get3rdPartyEInvoiceRequestDetail: [],
		},
	} = useGet3rdPartyEInvoiceRequestDetailQuery({
		variables: { billID: billID, outletID: outletID },
		fetchPolicy: 'network-only',
		onCompleted: ({ get3rdPartyEInvoiceRequestDetail }) => {},
	});

	// const sumPayment

	const billUserEInvoiceSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		email: CommonYupValidation.requireField(SystemMsgs.email()),
		contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
		identityType: CommonYupValidation.requireField('Identity Type is required'),
		identityNumber: CommonYupValidation.requireField(
			'Identity Number is required',
		),
		tin: CommonYupValidation.requireField('TIN is required'),
	});

	const {
		handleSubmit,
		register,
		errors,
		control,
		getValues,
		watch,
		formState: { isSubmitting, isSubmitted, dirtyFields, isDirty },
	} = useForm<BillUserEInvoiceFormProps>({
		defaultValues: {
			address: '',
			country: '',
			state: '',
			city: '',
			postcode: '',
		},
		mode: 'onSubmit',
		resolver: yupResolver(billUserEInvoiceSchema),
	});

	const sumPaymentAmount = payments => {
		let totalT = 0;
		payments?.map(x => {
			totalT += x?.receivedAmount - x?.changeAmount;
		});
		return totalT;
	};

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		submitDocumentEInvoice3rdparty,
		{
			loading: submitDocumentEInvoice3rdpartyLoading,
			called: submitDocumentEInvoice3rdpartyCalled,
		},
	] = useSubmitDocumentEInvoice3rdpartyMutation({
		onError: error => {
			// console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconID}/vo`);

			// setTimeout(() => {
			history.push({
				pathname: `/menux/outlet/${outletID}/bill-qr/${billID}`,
				state: { success: true, msgMode: 'create' },
			});
			//history.goBack();
			// }, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			// Auto reload after completion
			setTimeout(() => {
				window.location.reload(); // This will reload the page
			}, 500); // You can adjust the timeout if needed
		},
	});

	const onSubmit = () => {
		submitDocumentEInvoice3rdparty({
			variables: {
				billID: billID,
				outletID: outletID,
				integrate3rdPartyEInvoiceBuyerInput: {
					name: watch('name'),
					contactNo: watch('contactNo'),
					email: watch('email'),
					identityType: watch('identityType'),
					identityNumber: watch('identityNumber'),
					tin: watch('tin'),
					sstRegNo: watch('sstRegNo'),
					address: {
						address: state.address,
						state: state.state.name,
						city: state.city.name,
						country: state.country.name,
						postCode: state.postcode.postcode,
					},
				},
			},
		});
	};

	const datalist = get3rdPartyEInvoiceRequestDetail?.datalist;

	console?.log(watch('identityType'), 'identityType');

	const billDate = new Date(getBillAndOutletForNotAuth[0]?.createdTs);

	const currentDate = new Date();
	const threeDaysAgo = new Date(
		currentDate.getTime() - 3 * 24 * 60 * 60 * 1000,
	);

	// Convert both dates to timestamps for comparison
	const billDateTimestamp = billDate.getTime();
	const threeDaysAgoTimestamp = threeDaysAgo.getTime();

	console?.log(
		threeDaysAgo.getTime(),
		billDate,
		billDate < threeDaysAgo,
		'dated',
	);

	return (
		<>
			{billAndOutletForNotAuthLoading && <Loading />}

			<AuthLayout
				logo={
					getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
					getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
						? null
						: Logo
				}
				image={Background}
				className="margin-override"
			>
				<br></br>

				<CardContents
					style={{
						overflow: 'auto', // Enable vertical scrolling
						// overflowX: 'hidden',
						maxHeight:
							getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
							getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
								? 'calc(115vh - 170px)'
								: 'calc(100vh - 170px)', // Set a max height for the content area
						paddingRight: '10px', // Optional: to prevent horizontal scroll when content is too wide
						willChange: 'transform', // Hint to the browser for scrolling behavior
						marginTop:
							getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
							getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
								? '-40px'
								: '0px',
						marginBottom:
							getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
							getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl
								? '-10px'
								: '0px',
					}}
				>
					{getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ||
					getBillAndOutletForNotAuth[0]?.outlet?.company?.imageUrl ? (
						<Grid container>
							<Grid item xs={12} style={{ textAlign: 'center' }}>
								{getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL ? (
									<img
										className="icon-svg"
										style={{
											width: '200px',
											// marginTop: '20px',
											marginBottom: '10px',
											height: '100px',
										}}
										src={getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL}
										// crossOrigin="anonymous"
									/>
								) : (
									<img
										className="icon-svg"
										style={{
											height: '90px',
											width: '150px',
											// marginTop: '20px',
											marginBottom: '5px',
										}}
										src={getBillAndOutletForNotAuth[0]?.outlet?.logo?.fileURL}
									/>
								)}
							</Grid>
						</Grid>
					) : null}

					<Grid container>
						<Grid item xs={12} style={{ textAlign: 'center' }}>
							{getBillAndOutletForNotAuth[0]?.einvoiceRequestID === null ? (
								<span
									style={{
										fontFamily: 'Poppins, sans-serif',
										fontWeight: 700,
										fontSize: '24px',
										textAlign: 'center',
									}}
								>
									e-Invoice Request Form
								</span>
							) : (
								<span
									style={{
										fontFamily: 'Poppins, sans-serif',
										fontWeight: 700,
										fontSize: '24px',
										textAlign: 'center',
									}}
								>
									e-Invoice Request
								</span>
							)}
						</Grid>
					</Grid>
					<Card
						style={{
							backgroundColor: '#f0f0f0',
							boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Adds a shadow (MUI built-in shadow scale 0-24)
							borderRadius: '8px', // Optional: Adds rounded corners
							width: isDesktop ? '60%' : '100%', // Full width
							// justifyContent: 'center',
							// alignContent: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							marginLeft: isDesktop ? '18%' : '0%',
							marginTop: '4%',
							marginBottom: '4%',
						}}
					>
						<CardContent>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Document No
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{getBillAndOutletForNotAuth[0]?.billNo}
									</div>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Document Date
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{formatDate(getBillAndOutletForNotAuth[0]?.createdTs)}
									</div>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Currency
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									{' '}
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{getBillAndOutletForNotAuth[0]?.outlet?.currencyCode}
									</div>
								</Grid>
							</Grid>
							<Grid container>
								<Grid item xs={6} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										Amount
									</div>
								</Grid>
								<Grid item xs={1} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										:
									</div>
								</Grid>
								<Grid item xs={5} style={{ textAlign: 'left' }}>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
										}}
									>
										{sumPaymentAmount(
											getBillAndOutletForNotAuth[0]?.payments,
										).toFixed(2)}
									</div>
								</Grid>
							</Grid>
							{getBillAndOutletForNotAuth[0]?.einvoice_status === 'RECORDED' &&
							getBillAndOutletForNotAuth[0]?.einvoice_irbm_uniqueID === null ? (
								<Grid container>
									<Grid item xs={6} style={{ textAlign: 'left' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 400,
												lineHeight: '14px',
											}}
										>
											Status
										</div>
									</Grid>
									<Grid item xs={1} style={{ textAlign: 'left' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 400,
												lineHeight: '14px',
											}}
										>
											:
										</div>
									</Grid>
									<Grid item xs={5} style={{ textAlign: 'left' }}>
										<div
											style={{
												fontSize: '12px',
												fontWeight: 400,
												lineHeight: '14px',
											}}
										>
											In Progress
										</div>
									</Grid>
								</Grid>
							) : (
								<></>
							)}
						</CardContent>
					</Card>

					{getBillAndOutletForNotAuth[0]?.einvoiceRequestID !== null &&
					getBillAndOutletForNotAuth[0]?.einvoice_irbm_uniqueID !== null ? (
						<>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								onClick={() => window.open(`${datalist[0]?.pdfUrl}`)}
								data-qa="loginButton"
								style={{
									display: 'block',
									margin: '0 auto',
									marginBottom: '12px',
								}}
							>
								Get PDF Document
							</Button>
						</>
					) : getBillAndOutletForNotAuth[0]?.einvoice_status === 'NEW' &&
					  billDateTimestamp >= threeDaysAgoTimestamp ? (
						<>
							{/* <form id="submit-form" onSubmit={handleSubmit(onSubmit)}> */}
							<Card
								style={{
									backgroundColor: '#f0f0f0',
									boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Adds a shadow (MUI built-in shadow scale 0-24)
									borderRadius: '8px', // Optional: Adds rounded corners
									width: '100%', // Full width
									// justifyContent: 'center',
									// alignContent: 'center',
									// display: 'flex',
									// flexDirection: 'row',
									marginTop: '4%',
									marginBottom: '4%',
								}}
							>
								<CardContent>
									<div
										style={{
											fontSize: '12px',
											fontWeight: 400,
											lineHeight: '14px',
											fontStyle: 'italic',
										}}
									>
										Please complete the following form :
									</div>
									<Controller
										as={TextField}
										name="name"
										fullWidth
										margin="normal"
										label="Name"
										required
										helperText={errors?.name?.message}
										error={errors?.name ? true : false}
										control={control}
										ref={register}
										autoComplete="nope"
									/>
									<Controller
										as={
											<PhoneNumInput
												country={'my'}
												specialLabel="Contact No."
												fullWidth
												// inputProps={{
												// 	style: {
												// 		borderColor: errors?.contactNo ? '#E53935' : null,
												// 	},
												// }}
											/>
										}
										// customInput={TextField}
										name="contactNo"
										type="tel"
										marginTop
										marginBottom
										// required
										margin="normal"
										control={control}
										ref={register}
										helperText={errors?.contactNo?.message}
										error={errors?.contactNo ? true : false}
										autoComplete="off"
									/>
									<Controller
										as={TextField}
										name="email"
										label="Email Address"
										autoComplete="off"
										multiline={true}
										required
										fullWidth
										ref={register}
										control={control}
										margin="dense"
										helperText={errors?.email?.message}
										error={errors?.email ? true : false}
									></Controller>
									<Controller
										as={
											<TextField name="identityType">
												{identityTypeData?.map(x => (
													<MenuItem key={x.name} value={x.code}>
														{x.name}
													</MenuItem>
												))}
											</TextField>
										}
										name="identityType"
										margin="normal"
										label="Identity Type"
										fullWidth
										autoComplete="off"
										multiline={true}
										select
										control={control}
										ref={register}
										required
										helperText={errors?.identityType?.message}
										error={errors?.identityType ? true : false}
									/>
									<Controller
										as={TextField}
										name="identityNumber"
										label="Identity Number"
										autoComplete="off"
										multiline={true}
										required
										fullWidth
										ref={register}
										control={control}
										margin="dense"
										helperText={errors?.identityNumber?.message}
										error={errors?.identityNumber ? true : false}
										inputProps={{
											pattern: '[A-Za-z0-9]*', // Regex to allow only letters and digits
										}}
										onInput={e => {
											// Optional: You can sanitize the input by removing non-alphanumeric characters
											e.target.value = e.target.value.replace(
												/[^A-Za-z0-9]/g,
												'',
											);
										}}
									></Controller>
									<Controller
										as={TextField}
										name="tin"
										label="Tax Identification Number (TIN)"
										autoComplete="off"
										multiline={true}
										required
										fullWidth
										ref={register}
										control={control}
										margin="dense"
										helperText={errors?.tin?.message}
										error={errors?.tin ? true : false}
									></Controller>
									<Controller
										as={TextField}
										name="sstRegNo"
										label="SST Registration Number"
										autoComplete="off"
										multiline={true}
										// required
										fullWidth
										ref={register}
										control={control}
										margin="dense"
										helperText={errors?.sstRegNo?.message}
										error={errors?.sstRegNo ? true : false}
									></Controller>
									<AddressAutoCompleteComponent
										getCountry={getCountry}
										getStatebyCountry={getStatebyCountry}
										getCitybyState={getCitybyState}
										getPostcodebyCity={getPostcodebyCity}
										handleCountryOnChange={handleCountryOnChange}
										handleStateOnChange={handleStateOnChange}
										handleCityOnChange={handleCityOnChange}
										handlePostCodeOnChange={handlePostCodeOnChange}
										handleGoogleOnChange={handleGoogleOnChange}
										autoCompleteRef={autoCompleteRef}
										state={state}
										country={country}
										setCountry={setCountry}
										setState={setState}
										setCity={setCity}
										newState={newState}
										newPostcode={newPostcode}
										setPostcode={setPostcode}
										city={city}
										formType={mode}
										isSubmitted={isSubmitted}
										editData={editData}
									/>
									<Button
										type="submit"
										variant="contained"
										color={
											submitDocumentEInvoice3rdpartyLoading
												? ('inherit' as 'inherit')
												: 'primary'
										}
										disabled={
											submitDocumentEInvoice3rdpartyLoading ? true : false
										}
										// color="primary"
										className="login-btn"
										onClick={() => handleSubmit(onSubmit)()}
										data-qa="loginButton"
										// onClick={() => {
										// 	// history.push(loginRedirectPath);
										// }}
									>
										Submit
									</Button>{' '}
								</CardContent>
							</Card>
							{/* </form> */}
						</>
					) : (
						<>
							<div
								style={{
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '14px',
									fontStyle: 'italic',
									paddingTop: '10px',
									paddingBottom: '20px',
								}}
							>
								Sorry, you are not allowed to request e-invoice after 72 hours
								from document date
							</div>
						</>
					)}
					{/* <Card
						style={{
							backgroundColor: '#f0f0f0',
							boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Adds a shadow (MUI built-in shadow scale 0-24)
							borderRadius: '8px', // Optional: Adds rounded corners
							width: '100%', // Full width
							// justifyContent: 'center',
							// alignContent: 'center',
							// display: 'flex',
							// flexDirection: 'row',
							marginTop: '4%',
							marginBottom: '4%',
						}}
					>
						<CardContent>
							<div
								style={{
									fontSize: '12px',
									fontWeight: 400,
									lineHeight: '14px',
									fontStyle: 'italic',
								}}
							>
								Please complete the following form :
							</div>
							<Controller
								as={TextField}
								name="name"
								fullWidth
								margin="normal"
								label="Name"
								required
								helperText={errors?.name?.message}
								error={errors?.name ? true : false}
								control={control}
								ref={register}
								autoComplete="nope"
							/>
							<Controller
								as={
									<PhoneNumInput
										country={'my'}
										specialLabel="Contact No."
										fullWidth
										// inputProps={{
										// 	style: {
										// 		borderColor: errors?.contactNo ? '#E53935' : null,
										// 	},
										// }}
									/>
								}
								// customInput={TextField}
								name="contactNo"
								type="tel"
								marginTop
								marginBottom
								// required
								margin="normal"
								control={control}
								ref={register}
								helperText={errors?.contactNo?.message}
								error={errors?.contactNo ? true : false}
								autoComplete="off"
							/>
							<Controller
								as={TextField}
								name="email"
								label="Email Address"
								autoComplete="off"
								multiline={true}
								required
								fullWidth
								ref={register}
								control={control}
								margin="dense"
								helperText={errors?.email?.message}
								error={errors?.email ? true : false}
							></Controller>
							<Controller
								as={
									<TextField name="identityType">
										{identityTypeData?.map(x => (
											<MenuItem key={x.name} value={x.name}>
												{x.name}
											</MenuItem>
										))}
									</TextField>
								}
								name="identityType"
								margin="normal"
								label="Identity Type"
								fullWidth
								autoComplete="off"
								multiline={true}
								select
								control={control}
								ref={register}
								required
								helperText={errors?.identityType?.message}
								error={errors?.identityType ? true : false}
							/>
							<Controller
								as={TextField}
								name="identityNumber"
								label="Identity Number"
								autoComplete="off"
								multiline={true}
								required
								fullWidth
								ref={register}
								control={control}
								margin="dense"
								helperText={errors?.identityNumber?.message}
								error={errors?.identityNumber ? true : false}
							></Controller>
							<Controller
								as={TextField}
								name="tin"
								label="Tax Identification Number (TIN)"
								autoComplete="off"
								multiline={true}
								required
								fullWidth
								ref={register}
								control={control}
								margin="dense"
								helperText={errors?.tin?.message}
								error={errors?.tin ? true : false}
							></Controller>
							<Controller
								as={TextField}
								name="sstRegNo"
								label="SST Registration Number"
								autoComplete="off"
								multiline={true}
								required
								fullWidth
								ref={register}
								control={control}
								margin="dense"
								helperText={errors?.sstRegNo?.message}
								error={errors?.sstRegNo ? true : false}
							></Controller>
							<AddressAutoCompleteComponent
								getCountry={getCountry}
								getStatebyCountry={getStatebyCountry}
								getCitybyState={getCitybyState}
								getPostcodebyCity={getPostcodebyCity}
								handleCountryOnChange={handleCountryOnChange}
								handleStateOnChange={handleStateOnChange}
								handleCityOnChange={handleCityOnChange}
								handlePostCodeOnChange={handlePostCodeOnChange}
								handleGoogleOnChange={handleGoogleOnChange}
								autoCompleteRef={autoCompleteRef}
								state={state}
								country={country}
								setCountry={setCountry}
								setState={setState}
								setCity={setCity}
								newState={newState}
								newPostcode={newPostcode}
								setPostcode={setPostcode}
								city={city}
								// formType={mode}
								// isSubmitted={isSubmitted}
								// editData={editData}
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								className="login-btn"
								onClick={() => onSubmit()}
								data-qa="loginButton"
								// onClick={() => {
								// 	// history.push(loginRedirectPath);
								// }}
							>
								Submit
							</Button>{' '}
						</CardContent>
					</Card> */}

					{/* You can place additional content here if needed, but it won't trigger any page navigation */}
				</CardContents>
				{/* </form> */}
			</AuthLayout>
		</>
	);
};
