import React from 'react';
import { HomeIcon } from './HomeIcon';
import { RedirectHome } from './RedirectHome';

const homeRoutes = [
	{
		props: { exact: true, path: '/' },
		component: <HomeIcon />,
	},
	{
		props: { exact: true, path: '/home' },
		component: <HomeIcon />,
	},
	{
		props: { exact: true, path: '/redirect' },
		component: <RedirectHome />,
	},
];

export default homeRoutes;
