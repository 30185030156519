import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Snackbar,
	makeStyles,
} from '@material-ui/core';
import AppContext from 'containers/App/Store/AppContext';
import {
	CommonStatus,
	OrderItemStatus,
	OrderStatus,
	useGetOrderLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletQuery,
	useGetPrintDataSubscription,
	useGetPrinterOrderItemLazyQuery,
	useGetPrinterOrderItemQuery,
	useGetTableQuery,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { WSclient } from 'POSClient';
import {
	printReceipt,
	printReceiptText,
} from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceiptFunctions';
import CloseIcon from '@material-ui/icons/Close';
import { useApolloClient } from '@apollo/client';

const useStyles = makeStyles({
	spaced: {
		['& > *']: {
			marginRight: '0.5rem',
		},
		['& :not(span)']: {
			width: '0.8rem',
			height: '0.8rem',
		},
	},
});

export const PrinterWrapper = (props: any) => {
	const { outletID } = useParams<Record<string, any>>();
	const classes = useStyles();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const printOutletID = localStorage.getItem('latestOutlet');
	const login = localStorage.getItem('login');

	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [snackbarClosed, setsnackbarClosed] = useState(false);
	const [printOnce, setPrintOnce] = useState(false);

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const history = useHistory();
	const location = useLocation();
	const client = useApolloClient();

	// Drawer Toggler
	const handleDrawer = () => {
		localStorage.removeItem('searchFilter');
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	//subscription //subscription //subscription

	const {
		loading: getPrintDataLoading,
		data: { getPrintData } = { getPrintData: {} as any },
	} = useGetPrintDataSubscription({
		variables: {
			outletID: printOutletID,
		},
		client: WSclient,
	});

	const [
		loadPrinterOrderItem,
		{
			loading: getPrinterOrderItemLoading,
			data: { getPrinterOrderItem } = { getPrinterOrderItem: [] },
		},
	] = useGetPrinterOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			generateReceiptData();
		},
	});
	const [
		loadOrder,
		{ loading: orderLoading, data: { getOrder } = { getOrder: [] } },
	] = useGetOrderLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			setPrintOnce(true);
		},
	});

	useEffect(() => {
		if (
			(location?.pathname !== '/login' ?? location?.pathname !== '/logout') &&
			getPrintData?.printerName
		) {
			loadPrinterOrderItem({
				variables: {
					orderID: getPrintData?.orderID,
					orderItemIDs: getPrintData?.orderItemIDs,
				},
			});
			loadOrder({
				variables: {
					ID: getPrintData?.orderID,
					status: OrderStatus.Open,
					outletID: printOutletID,
				},
			});
		}
	}, [getPrintData]);

	// console.log('getPrintData', getPrintData);

	const [uniqueKpArray, setUniqueKpArray] = useState([]);
	const [kpObj, setKpObject] = useState({});
	const [printerName, setPrinterName] = useState(null);
	const [kitchenPrinterObj, setKitchenPrinterObj] = useState([]);
	const [shouldQueryRun, setShouldQueryRun] = useState(false); // Track whether to run the query

	// useEffect(() => {
	// 	if (getPrinterOrderItem?.length > 0) {
	// 		generateReceiptData();
	// 	}
	// }, [getPrinterOrderItem]);

	const generateReceiptData = () => {
		let uniqueprinterName = new Set(
			getPrinterOrderItem
				?.filter(oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined))
				?.map(oi => oi?.menuItem?.kitchenPrinter?.name),
		);
		setUniqueKpArray([...uniqueprinterName!]);

		let temporaryObject = {};

		getPrinterOrderItem?.map(oi => {
			if (!(oi?.menuItem?.kitchenPrinter?.name in temporaryObject)) {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name] = [oi];
			} else {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name].push(oi);
			}
		});
		setKpObject(temporaryObject);
	};

	// Define the routes where you DON'T want to run the query
	const blockedRoutes = ['/login'];

	useEffect(() => {
		if (login && !blockedRoutes.includes(location.pathname)) {
			setShouldQueryRun(true);
		}
	}, [location.pathname]);
	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
		skip: !shouldQueryRun,
	});

	console?.log(printOutletID, 'printOutletID');

	let obj;
	const handlePrinting = async () => {
		if (uniqueKpArray?.length > 0) {
			await uniqueKpArray?.map(async (printerName, index) => {
				obj = kpObj[printerName];
				setKitchenPrinterObj(obj);
				setPrinterName(printerName);
			});

			printReceiptText(
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				getPrintData.printerName,
				'server',
				null,
				'order-receipt',
				null,
				getPrintData.outletName,
				getOrder[0],
				getOrder[0]?.table,
				obj,
				getPrintData.orderMode,
				null,
				getPrintData.voidQuantity,
				getPrintData.reasonCode,
				getPrintData.remark,

				null,
				null,
				null,
				null,
				null,
				getUsersByAccountAndSoftware,
				null,
				null,
				null,
			);

			console.log('obj', obj);
			setPrintOnce(false);

			const perforatedArray = obj
				?.filter(v => v?.menuItem?.isPerforated === true)
				?.flatMap(x => {
					if (x?.menuItem?.isPerforated && x?.menuItem?.perforatedQuantity) {
						return Array.from(
							{
								length:
									x?.menuItem?.perforatedQuantity *
									(getPrintData?.orderMode === 'void'
										? getPrintData?.voidQuantity
										: x?.quantity),
							},
							() => ({
								...x,
								idvPerforated: true,
								menuItem: {
									...x?.menuItem,
									isPerforated: false,
								},
							}),
						);
					}
					return [x];
				});

			console.log('perforatedArray', perforatedArray);
			if (perforatedArray?.length > 0) {
				for (const perforated of perforatedArray) {
					console.log('masuk');
					printReceiptText(
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						null,
						getPrintData.printerName,
						'server',
						null,
						'order-receipt',
						null,
						getPrintData.outletName,
						getOrder[0],
						getOrder[0]?.table,
						[perforated],
						getPrintData.orderMode,
						null,
						getPrintData.voidQuantity,
						getPrintData.reasonCode,
						getPrintData.remark,

						null,
						null,
						null,
						null,
						null,
						getUsersByAccountAndSoftware,
						null,
						null,
						null,
					);
				}
			}

			setPrintOnce(false);
		}
		return true;
	};

	useEffect(() => {
		if (
			uniqueKpArray?.length > 0 &&
			printOnce === true
			// && getOrder?.length > 0
		) {
			// printReceipt(getPrintData.dataURL, getPrintData.printerName, 'server');
			handlePrinting();
			// window.location.reload();
		}

		return () => {
			// Unsubscribe from the subscription
			client.stop();
		};
	}, [
		// uniqueKpArray,
		uniqueKpArray?.length,
		getOrder?.length,
		printOnce,
	]);
	const handleSnackbarClose = () => {
		setsnackbarClosed(true);
		setOpen(false);
	};

	return (
		<>
			{
				!getPrinterOrderItemLoading
				// && !orderLoading
			}
			<Snackbar
				className=""
				style={{ top: '10px', right: '10px' }}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={open}
				autoHideDuration={3000}
				onClose={() => setOpen(true)}
				ContentProps={{
					'aria-describedby': 'message-id',
					style: { backgroundColor: 'white', color: 'red' },
				}}
				message={<span id="message-id">Listening to print requests...</span>}
				action={
					// Show IconButton with Close icon if notification is START
					<IconButton
						key="close"
						aria-label="close"
						color="inherit"
						onClick={handleSnackbarClose}
					>
						<CloseIcon />
					</IconButton>
				}
			/>
		</>
	);
};
