import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import {
	Box,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { ReducerProps } from 'components/Dialog/DialogInterface';
import { SearchField } from 'components/Dialog/SearchDialogComponent';
import {
	GetHomeTableSummaryDocument,
	GetOrderDocument,
	GetTableSubmenuTableDocument,
	OrderStatus,
	PatronClass,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { IAction } from 'helpers/model';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ChangePatronConfirmationDialog } from './ChangePatronConfirmationDialog';
import BigNumber from 'bignumber.js';

interface EditOrderInfoProps {
	qrID: string;
	outletID: string;
	docNo: string;
	orderID: string;
	pax: number;
	patronAccountID: string;
	patronID: string;
	contactNo: string;
	patronName: string;
	patronClass: PatronClass;
}

export const EditOrderInfoDialog = (props: any) => {
	const {
		editData,
		mode,
		tableID,
		orderID,
		outletID,
		editOrderInfo,
		setEditOrderInfo,
		getOrder,
		selectedTable,
		getPatron,
		hotelID,
		updateOrder,
		updateOrderLoading,
	} = props;

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const yupSchema = yup.object().shape({
		pax: yup.string().required(SystemMsgs.pax()),
	});

	const {
		register,
		clearErrors,
		handleSubmit,
		watch,
		control,
		setValue,
		getValues,
		errors,
	} = useForm<EditOrderInfoProps>({
		defaultValues: {
			docNo: editData?.docNo,
			pax: editData?.pax,
			patronID: editData?.patronID,
			patronName: editData?.patronName,
			contactNo: editData?.contactNo,
			// patronAccountID: editData?.patronAccountID,
		},
		resolver: yupResolver(yupSchema),
	});

	const selectionData = mode?.menuOptionItem as any;

	useEffect(() => {
		if (mode === 'edit' && selectionData?.length > 0) {
			// register('');
		}
	}, [mode, selectionData]);

	//TRANSFER EDIT ORDER INFO USE MUTATION
	//TRANSFER EDIT ORDER INFO USE MUTATION
	//TRANSFER EDIT ORDER INFO USE MUTATION

	// const { setOpenSnackBar, setSnackBarMsg } = useContext(
	// 	SnackBarContext as any,
	// );

	// const [updateOrder, { loading: updateOrderLoading }] = useUpdateOrderMutation(
	// 	{
	// 		onError: error => {
	// 			console.log('ERROR', error);
	// 		},
	// 		onCompleted: data => {
	// 			setOpenSnackBar(true);
	// 			setSnackBarMsg(SystemMsgs.updateRecord());
	// 		},
	// 	},
	// );

	const patronAssign = getPatron
		?.filter(x => x?.ID === watch('patronID'))
		?.map(a => a?.patronClass);

	const onSubmit = (data: any) => {
		updateOrder({
			variables: {
				input: {
					ID:
						selectedOrder?.ID === undefined
							? orderArray[0]?.ID
							: selectedOrder?.ID,
					qrID:
						selectedOrder?.qrID === undefined
							? orderArray[0]?.qrID
							: selectedOrder?.qrID,
					tableID: tableID,
					outletID: outletID,
					pax: parseFloat(data?.pax),
					patronID: data.patronID,
					patronAccountID: changePatronAccountID,
					patronName:
						visitorDropdown === true
							? data?.patronName
							: staffComponent === true
							? selectedStaff === 0
								? `${defaultStaff?.designation} | ${defaultStaff?.name}`
								: `${selectedStaff?.designation} | ${selectedStaff?.name}`
							: officerComponent === true
							? selectedOfficer === 0
								? `${defaultOfficer?.designation} | ${defaultOfficer?.name}`
								: `${selectedOfficer?.designation} | ${selectedOfficer?.name}`
							: cardDropdown === true
							? selectedRoom === 0
								? `${defaultRoom?.RoomNo ? `${defaultRoom?.RoomNo} |` : ''} ${
										defaultRoom?.GuestName
								  }`
								: `${selectedRoom?.RoomNo ? `${selectedRoom?.RoomNo} |` : ''} ${
										selectedRoom?.GuestName
								  }`
							: null,
					contactNo: visitorDropdown === true ? data?.contactNo : null,
				},
			},

			refetchQueries: [
				{
					query: GetHomeTableSummaryDocument,
					variables: { tableID: tableID },
				},
				{
					query: GetTableSubmenuTableDocument,
					variables: {
						outletID: outletID,
						orderByAsc: 'sequence',
						isActive: true,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						outletID: outletID,
						tableID: tableID,
						status: OrderStatus.Open,
					},
				},
			],
		});
		setEditOrderInfo(false);
		setCardDropdown(false);
		setCardDropdownMember(false);
		setCheckboxDropdown(false);
		setMemberListDropdown(false);
		setSelectedOrder({});
		clearErrors();
	};

	/// USESTATE FOR SO MANY OPTION POP UP
	/// USESTATE FOR SO MANY OPTION POP UP
	/// USESTATE FOR SO MANY OPTION POP UP

	const [cardDropdown, setCardDropdown] = useState(false);
	const [cardDropdownMemebr, setCardDropdownMember] = useState(false);
	const [checboxDropdown, setCheckboxDropdown] = useState(false);
	const [memberListDropdown, setMemberListDropdown] = useState(false);
	const [visitorDropdown, setVisitorDropdown] = useState(false);
	const [staffComponent, setStafComponent] = useState(false);
	const [officerComponent, setOfficerComponent] = useState(false);
	const [disable, setDisable] = useState(false);
	const [updateConfirm, setUpdateConfirm] = useState(false);

	// const [hotelTempState, setHotelTempState] = useState('');
	// const [staffState, setStaffState] = useState('');
	// const [officerState, setOfficerState] = useState('');

	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);
	const [selectedRoom, setSelectedRoom] = useState({} as any);
	const [changePatronAccountID, setChangePatronAccountID] = useState();
	const [defaultStaff, setDefaultStaff] = useState({} as any);
	const [defaultOfficer, setDefaultOfficer] = useState({} as any);
	const [defaultRoom, setDefaultRoom] = useState({} as any);
	useEffect(() => {
		if (patronAssign[0] === 'Visitor') {
			return setChangePatronAccountID(null);
		} else if (patronAssign[0] === 'Member') {
			return setChangePatronAccountID(null);
		} else if (patronAssign[0] === 'Hotel Guest') {
			return setChangePatronAccountID(selectedRoom?.RegistrationID);
		} else if (patronAssign[0] === 'Staff') {
			return setChangePatronAccountID(selectedStaff.ID);
		} else if (patronAssign[0] === 'Officer') {
			return setChangePatronAccountID(selectedOfficer.ID);
		}
	});

	const [selectedOrder, setSelectedOrder] = useState(getOrder[0]);

	const orderArray: any[] = getOrder;

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const [radioError, setRadioError] = useState(false);

	const handleSelectedOrder = order => {
		setSelectedOrder(order);
		setValue('pax', order?.pax);
		setValue('patronID', order?.patronID);
		setValue('patronName', order?.patronName);
		setValue('contactNo', order?.contactNo);
		// setValue(
		// 	'patronAccountID',
		// 	order?.patronAccountID
		// 		? getStaff?.filter(x => x?.ID === order?.patronAccountID)[0]?.name
		// 		: null,
		// );
	};

	// state
	const [filterCond, setFilterCond] = useState({
		hotel: false,
		officer: false,
		staff: false,
	});

	const [tempState, setTempState] = useState('');

	const initialState = {
		cardDropDown: false,
		cashAmount: false,
		hotelSearchField: false,
		nonGuestSearchField: false,
		debtorSearchField: false,
		officerSearchField: false,
		staffSearchField: false,
		voucher: false,
		others: false,
	};

	const reducer: Reducer<ReducerProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	// useEffect(() => {
	// 	if (getOrder?.length > 0) {
	// 		handleSelectedOrder(getOrder[0]);
	// 	}
	// }, [editOrderInfo]);

	const handleRadio = patronClass => {
		if (patronClass === 'Visitor') {
			setVisitorDropdown(true);
			setCardDropdown(false);
			setOfficerComponent(false);
			setStafComponent(false);
			setValue('patronName', selectedOrder?.patronName);
		} else if (patronClass === 'Member') {
			setCardDropdown(false);
			setVisitorDropdown(false);
			setMemberListDropdown(false);
			setStafComponent(false);
			setOfficerComponent(false);
		} else if (patronClass === 'Hotel Guest') {
			setVisitorDropdown(false);
			setOfficerComponent(false);
			setStafComponent(false);
			setCardDropdown(true);
			loadMenuXInhouseList({
				variables: {
					hotelID: hotelID,
					outletID: outletID,
				},
			});
		} else if (patronClass === 'Officer') {
			setOfficerComponent(true);
			setVisitorDropdown(false);
			setCardDropdown(false);
			setStafComponent(false);
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
		} else if (patronClass === 'Staff') {
			setStafComponent(true);
			setVisitorDropdown(false);
			setCardDropdown(false);
			setOfficerComponent(false);
			loadStaff({
				variables: {
					isActive: true,
				},
			});
		}
	};

	//-------------------------- HOTEL --------------------------//
	const [
		loadMenuXInhouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getMenuXInhouseList }) => {
			setDefaultRoom(
				selectedOrder?.patronAccountID === null
					? {}
					: getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
							x => x?.RegistrationID === selectedOrder?.patronAccountID,
					  )[0],
			);
		},
	});
	const hotelGuest = getMenuXInhouseList?.data?.MenuXInhouseList;

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (hotelGuest && hotelGuest.length > 0) {
			setOriginalListing(hotelGuest);
		}
	}, [hotelGuest]);

	const handleSelectedRoom = room => {
		setSelectedRoom(room);
	};

	//-------------------------- STAFF --------------------------//
	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getStaff }) => {
			setDefaultStaff(
				getStaff?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0],
			);
		},
	});

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	useEffect(() => {
		if (getStaff && getStaff?.length > 0) {
			setOriginalListing1(getStaff);
		}
	}, [getStaff]);

	const handleSelectedStaff = staff => {
		setSelectedStaff(staff);
	};

	//-------------------------- OFFICER --------------------------//
	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOfficer }) => {
			let defaultOrder = selectedOrder;
			if (defaultOrder?.patronAccountID !== null) {
				setDefaultOfficer(
					getOfficer?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0],
				);
			}
		},
	});

	const {
		filteredList: filteredList2,
		handleSearch: handleSearch2,
		setOriginalListing: setOriginalListing2,
	} = useFuseSearch();

	useEffect(() => {
		if (getOfficer && getOfficer?.length > 0) {
			setOriginalListing2(getOfficer);
		}
	}, [getOfficer]);

	const handleSelectedOfficer = officer => {
		setSelectedOfficer(officer);
	};

	/////////////DEFAULT RADIO CHECK////////////////
	useEffect(() => {
		handleRadio(selectedOrder?.patron?.patronClass);
	}, [selectedOrder]);

	useEffect(() => {
		let p = getPatron
			?.filter(x => x?.ID === watch('patronID'))
			?.map(a => a?.patronClass);
		if (p[0] === 'Hotel Guest') {
			if (
				Object?.values(selectedRoom ?? {})?.length <= 0 &&
				Object?.values(defaultRoom ?? {})?.length <= 0
			) {
				return setDisable(true), setSelectedOfficer(0), setSelectedStaff(0);
			} else {
				return setDisable(false);
			}
		} else if (p[0] === 'Officer') {
			if (
				Object?.values(selectedOfficer ?? {})?.length <= 0 &&
				Object?.values(defaultOfficer ?? {})?.length <= 0
			) {
				return setDisable(true), setSelectedRoom(0), setSelectedStaff(0);
			} else {
				return setDisable(false);
			}
		} else if (p[0] === 'Staff') {
			if (
				Object?.values(selectedStaff ?? {})?.length <= 0 &&
				Object?.values(defaultStaff ?? {})?.length <= 0
			) {
				return setDisable(true), setSelectedRoom(0), setSelectedOfficer(0);
			} else {
				return setDisable(false);
			}
		} else {
			return (
				setDisable(false),
				setSelectedRoom(0),
				setSelectedOfficer(0),
				setSelectedStaff(0)
			);
		}
	});

	console.log('watch >>>', watch());
	console.log('selectedTable >>>', selectedTable);
	console.log('selectedOrder >>>', selectedOrder);
	console.log('editData >>>', editData);

	console.log(
		'apa >>>',
		selectedTable?.seatCount,
		selectedTable?.orderInfo?.totalPax,
		selectedOrder?.pax,
		selectedTable?.seatCount -
			selectedTable?.orderInfo?.totalPax +
			selectedOrder?.pax,
	);

	return (
		<>
			<form id="submit-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={editOrderInfo}
						onClose={() => {
							setEditOrderInfo(false);
							setCardDropdown(false);
							setCardDropdownMember(false);
							setCheckboxDropdown(false);
							setMemberListDropdown(false);
							setVisitorDropdown(false);
							// setSelectedOrder({});
						}}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="title highlight-text">
													Edit Order Info
												</div>
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{selectedOrder?.patron?.patronClass === undefined
													? orderArray[0]?.patron?.patronClass
													: selectedOrder?.patron?.patronClass}
											</div>
										</div>
										{/* <div className="session">
											<div className="flex session">
												<div
													className="xsTitle flex-space infoline-content"
													style={{ color: 'black' }}
												>
													{selectedOrder?.patron?.patronClass === null
														? ''
														: selectedOrder?.patron?.patronClass}
													
												</div>
											</div>
										</div> */}
									</div>
								),
							},
							body: () => (
								<>
									<div className="rm-padding table-wrap sample">
										<Controller
											as={
												<TextField required defaultValue={selectedOrder?.ID}>
													{getOrder
														?.filter(x => x?.ID !== orderID)
														.map((x, index) => (
															<MenuItem
																key={x.ID}
																value={x.ID}
																onClick={e => {
																	handleSelectedOrder(x);
																}}
																selected={
																	selectedOrder?.ID === undefined
																		? orderArray[0]?.ID
																		: selectedOrder.ID
																}
															>
																{x.docNo}{' '}
																<span className="highlight-text">
																	{' '}
																	&nbsp;
																	{/* {x?.patronAccount?.name} */}
																</span>
															</MenuItem>
														))}
												</TextField>
											}
											name="orderID"
											margin="normal"
											label="Order No."
											fullWidth
											autoComplete="off"
											select
											control={control}
											ref={register}
										/>

										<Controller
											as={TextField}
											name="pax"
											margin="normal"
											label="No. of Pax"
											fullWidth
											required
											autoComplete="off"
											defaultValue={orderArray[0]?.pax}
											control={control}
											ref={register}
											helperText={
												watch('pax') >
												selectedTable?.seatCount -
													selectedTable?.orderInfo?.totalPax +
													selectedOrder?.pax
													? 'Exceed total seat count'
													: null
											}
											error={
												watch('pax') >
												selectedTable?.seatCount -
													selectedTable?.orderInfo?.totalPax +
													selectedOrder?.pax
											}
										/>
										<div
											style={{
												color: '#939393',
												fontSize: '13px',
												marginTop: '15px',
												marginBottom: '-10px',
											}}
										>
											New Customer Type
										</div>
										<Controller
											as={
												<RadioGroup>
													{getPatron?.map((getPatron, index) => {
														return (
															<>
																<FormControlLabel
																	key={index}
																	value={getPatron.ID}
																	control={<Radio color="primary" />}
																	label={toProperCase(getPatron.patronClass)}
																	onClick={() => {
																		setTempState('');
																		handleRadio(getPatron?.patronClass);
																	}}
																/>
																{/* {getPatron?.patronClass == 'Hotel Guest' &&
																cardDropdown === true ? (
																	<>
																		<Controller
																			as={
																				<TextField
																					style={{
																						marginTop: '5px',
																						marginBottom: '10px',
																					}}
																					onFocus={e => {
																						handleSearch(
																							`'"${e?.target?.value}"`,
																							[`GuestName`, `RoomNo`],
																						);
																						setHotelTempState(e?.target?.value);
																					}}
																				/>
																			}
																			onInput={e => {
																				handleSearch(`'"${e?.target?.value}"`, [
																					`GuestName`,
																					`RoomNo`,
																				]);
																				setHotelTempState(e.target.value);
																			}}
																			defaultValue={defaultRoom?.GuestName}
																			label="Search Hotel Guest"
																			variant="standard"
																			required
																			autoComplete="off"
																			name="patronAccountID"
																			control={control}
																			register={register}
																		/>
																	</>
																) : null} */}

																{/* {getPatron?.patronClass == 'Staff' &&
																staffComponent === true ? (
																	<>
																		<TextField
																			style={{
																				marginTop: '5px',
																				marginBottom: '10px',
																			}}
																			onChange={e => {
																				handleSearch1(`'${e?.target?.value}`, [
																					`name`,
																				]);
																				setStaffState(e.target.value);
																			}}
																			label="Search Staff"
																			variant="standard"
																			// defaultValue={
																			// 	selectedOrder?.patronAccountID
																			// 		? defaultRoom?.GuestName
																			// 		: null
																			// }
																		></TextField>
																	</>
																) : null} */}

																{/* {getPatron?.patronClass == 'Officer' &&
																officerComponent === true ? (
																	<>
																		<TextField
																			style={{
																				marginTop: '5px',
																				marginBottom: '10px',
																			}}
																			onChange={e => {
																				handleSearch2(`'${e?.target?.value}`, [
																					`name`,
																				]);
																				setOfficerState(e.target.value);
																			}}
																			label="Search Officer"
																			variant="standard"
																			// defaultValue={
																			// 	selectedOrder?.patronAccountID
																			// 		? defaultRoom?.GuestName
																			// 		: null
																			// }
																		></TextField>
																	</>
																) : null} */}

																{getPatron?.patronClass == 'Visitor' &&
																visitorDropdown === true ? (
																	<>
																		<div style={{ marginTop: '-15px' }} />

																		<Controller
																			as={TextField}
																			name="patronName"
																			label="Name"
																			defaultValue={
																				selectedOrder?.patronName === undefined
																					? orderArray[0]?.patronName ===
																					  ' undefined'
																						? null
																						: orderArray[0]?.patronName
																					: selectedOrder?.patronName ===
																					  ' undefined'
																					? null
																					: selectedOrder?.patronName
																			}
																			autoComplete="off"
																			control={control}
																			multiline={true}
																			fullWidth
																			margin="dense"
																			ref={register}
																			style={{ marginBottom: '30px' }}
																			helperText={errors?.patronName?.message}
																			error={errors?.patronName ? true : false}
																		/>

																		<Controller
																			as={
																				<PhoneNumInput
																					country={'my'}
																					specialLabel="Contact No."
																					fullWidth
																					// inputProps={{
																					// 	style: {
																					// 		borderColor: errors?.contactNo
																					// 			? '#E53935'
																					// 			: null,
																					// 	},
																					// }}
																				/>
																			}
																			// customInput={TextField}
																			name="contactNo"
																			defaultValue={
																				selectedOrder?.contactNo === undefined
																					? orderArray[0]?.contactNo
																					: selectedOrder?.contactNo
																			}
																			// required
																			marginTop
																			marginBottom
																			margin="normal"
																			type="tel"
																			// autoComplete="off"
																			control={control}
																			ref={register}
																			helperText={errors?.contactNo?.message}
																			error={errors?.contactNo ? true : false}
																		/>

																		{/* <div style={{ marginTop: '15px' }} /> */}

																		{/* {errors?.contactNo && (
																		<div
																			style={{
																				color: '#E53935',
																				fontWeight: 400,
																				margin: '5px 0px 20px',
																			}}
																			className="mdDesc"
																		>
																			{errors?.contactNo?.message}
																		</div>
																	)} */}
																	</>
																) : null}

																{!MenuXInhouseListLoading &&
																	getPatron?.patronClass == 'Hotel Guest' &&
																	cardDropdown === true && (
																		<SearchField
																			data={getPatron?.patronClass}
																			patronEnum={'Hotel Guest'}
																			tempState={tempState}
																			reducerState={cardDropdown}
																			label={'Search Hotel Guest'}
																			defaultListField={{
																				defaultField: defaultRoom,
																				patronEnum: 'Hotel Guest',
																				data: 'GuestName',
																				type: 'RoomNo',
																				balance: 'CreditBalance',
																			}}
																			listField={{
																				filterCondition: 'GuestType',
																				fieldKey: ['RoomGuest', 'NonGuest'],
																				arg: 'RegistrationID',
																				filteredList: filteredList,
																				setSelectedListItem: setSelectedRoom,
																				selectedListItem: selectedRoom,
																				data: 'GuestName',
																				type: 'RoomNo',
																				balance: 'CreditBalance',
																				patronEnum: 'Hotel Guest',
																			}}
																			selectedField={selectedRoom}
																			defaultField={defaultRoom}
																			selectedOrder={selectedOrder}
																			setSelectedField={setSelectedRoom}
																			total={0}
																			onChange={e => {
																				handleSearch(`'"${e?.target?.value}"`, [
																					`GuestName`,
																					`RoomNo`,
																				]);
																				setTempState(e?.target?.value);
																			}}
																			defaultValue={
																				selectedOrder?.patronAccountID
																					? getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
																							x =>
																								x?.RegistrationID ===
																								selectedOrder?.patronAccountID,
																					  )[0]?.GuestName
																					: null
																			}
																		/>
																	)}
																{!getStaffLoading &&
																	getPatron?.patronClass == 'Staff' &&
																	staffComponent === true && (
																		<SearchField
																			data={getPatron?.patronClass}
																			patronEnum={'Staff'}
																			tempState={tempState}
																			reducerState={staffComponent}
																			label={'Search Staff'}
																			defaultListField={{
																				defaultField: defaultStaff,
																				patronEnum: 'Staff',
																				data: 'name',
																				type: 'designation',
																				balance: 'mobileNo',
																			}}
																			listField={{
																				// filterCondition: '',
																				fieldKey: ['STAFF'],
																				arg: 'ID',
																				filteredList: filteredList1,
																				setSelectedListItem: setSelectedStaff,
																				selectedListItem: selectedStaff,
																				balance: 'mobileNo',
																				data: 'name',
																				type: 'designation',
																				patronEnum: 'Staff',
																			}}
																			selectedField={selectedStaff}
																			defaultField={defaultStaff}
																			selectedOrder={selectedOrder}
																			setSelectedField={setSelectedStaff}
																			total={0}
																			onChange={e => {
																				handleSearch1(
																					`'"${e?.target?.value}"`,
																					['name', 'designation'],
																				);
																				setTempState(e?.target?.value);
																			}}
																			defaultValue={
																				selectedOrder?.patronAccountID
																					? getStaff?.filter(
																							x =>
																								x?.ID ===
																								selectedOrder?.patronAccountID,
																					  )[0]?.name
																					: null
																			}
																		/>
																	)}

																{!getOfficerLoading &&
																	getPatron?.patronClass == 'Officer' &&
																	officerComponent === true && (
																		<SearchField
																			data={getPatron?.patronClass}
																			patronEnum={'Officer'}
																			tempState={tempState}
																			reducerState={officerComponent}
																			label={'Search Officer'}
																			defaultListField={{
																				defaultField: defaultOfficer,
																				patronEnum: 'Officer',
																				data: 'name',
																				type: 'designation',
																				balance: 'mobileNo',
																			}}
																			listField={{
																				// filterCondition: 'GuestType',
																				fieldKey: ['OFFICER'],
																				arg: 'ID',
																				filteredList: filteredList2,
																				setSelectedListItem: setSelectedOfficer,
																				selectedListItem: selectedOfficer,
																				data: 'name',
																				type: 'designation',
																				balance: 'mobileNo',
																				patronEnum: 'Officer',
																			}}
																			selectedField={selectedOfficer}
																			defaultField={defaultOfficer}
																			selectedOrder={selectedOrder}
																			setSelectedField={setSelectedOfficer}
																			total={0}
																			onChange={e => {
																				handleSearch2(
																					`'"${e?.target?.value}"`,
																					['name', 'designation'],
																				);
																				setTempState(e?.target?.value);
																			}}
																			defaultValue={
																				selectedOrder?.patronAccountID
																					? getOfficer
																							?.filter(
																								x =>
																									x?.ID ===
																									selectedOrder?.patronAccountID,
																							)
																							?.map(x => x.name)
																					: null
																			}
																		/>
																	)}
															</>
														);
													})}
												</RadioGroup>
											}
											name="patronID"
											defaultValue={orderArray[0]?.patronID}
											control={control}
											ref={register}
											style={{ width: '100%' }}
											className="place-content-center"
											onChange={e => {
												register('patronID');
												setValue('patronID', e.target.value);
											}}
										/>
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												setEditOrderInfo(false);
												setCardDropdown(false);
												setCardDropdownMember(false);
												setCheckboxDropdown(false);
												setVisitorDropdown(false);
												setSelectedOrder({});
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',

										props: {
											variant: 'contained',
											// form: 'submit-form',
											type: 'submit',
											onClick: () => {
												if (errors?.pax ? false : true) {
													if (selectedOrder?.patronID === watch('patronID')) {
														handleSubmit(onSubmit)();
													} else if (
														selectedOrder?.patronID !== watch('patronID') &&
														selectedOrder?.totalDiscountAmount === 0
													) {
														handleSubmit(onSubmit)();
													} else {
														setUpdateConfirm(true);
													}
												}
											},
											color:
												disable ||
												updateOrderLoading ||
												watch('pax')?.toString() === '0' ||
												watch('pax')?.toString() === '' ||
												watch('pax') >
													selectedTable?.seatCount -
														selectedTable?.orderInfo?.totalPax +
														selectedOrder?.pax
													? 'inherit'
													: 'primary',
											disabled:
												disable ||
												updateOrderLoading ||
												watch('pax')?.toString() === '0' ||
												watch('pax')?.toString() === '' ||
												watch('pax') >
													selectedTable?.seatCount -
														selectedTable?.orderInfo?.totalPax +
														selectedOrder?.pax,
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			{updateConfirm && (
				<ChangePatronConfirmationDialog
					updateConfirm={updateConfirm}
					setUpdateConfirm={setUpdateConfirm}
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
				/>
			)}
		</>
	);
};
