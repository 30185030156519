export const useFilterOption = () => {
  const filterOptions = (options, { inputValue }, properties) => {
    const searchTerms = inputValue.toLowerCase().split(' ').filter(term => term !== '');
  
    return options.filter(option =>
      searchTerms.every(term =>
        properties.some(property =>
          option[property] && option[property].toLowerCase().includes(term)
        )
      )
    );
  }

  return {
    filterOptions,
  } 
}