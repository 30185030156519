import { useLazyQuery } from '@apollo/react-hooks';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	COUNTRY,
	GET_ACCOUNT_DETAIL,
	GET_CURRENCY,
	GET_PACKAGE_BY_ACCOUNT,
	GET_SUBSCRIPTION_ENTITY_CONTROL,
} from 'containers/AccountModule/AccountQuery';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetHomeSummaryQuery,
	useGetOutletLazyQuery,
	useGetUserLogoLazyQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import localForage from 'localforage';
import { AccountClient } from 'ProductsClient';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

export const RedirectHome = () => {
	useEffect(() => {
		console.log('HomeIcon');
	}, []);
	let history = useHistory();
	let location = useLocation();
	const [outletID, setOutletID] = useState(null);
	const [redirect, setRedirect] = useState(false);

	const { globalState, dispatch }: any = useContext(AppContext as any);
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const [
		loadOutlet,
		{
			called: outletCalled,
			loading: outletLoading,
			error: outletError,
			data: { getOutlet } = { getOutlet: [] },
		},
	] = useGetOutletLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOutlet }) => {
			localStorage.setItem(
				'latestOutlet',
				loggedInUserProfile?.lastestAccessedEntity ?? getOutlet[0]?.ID,
			);
			setOutletID(
				loggedInUserProfile?.lastestAccessedEntity ?? getOutlet[0]?.ID,
			);
			setRedirect(true);
		},
	});

	const {
		loading,
		error,
		data: { loggedInUserProfile, getRolePermission, getPOSPermission } = {
			loggedInUserProfile: null,
			getRolePermission: [],
			getPOSPermission: [],
		},
	} = useGetHomeSummaryQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			loadOutlet();
		},
	});

	const [
		loadAccountInfos,
		{
			loading: accountLoading,
			error: accountError,
			data: { getAccountDetail } = {
				getAccountDetail: {},
			},
		},
	] = useLazyQuery(GET_ACCOUNT_DETAIL, {
		client: AccountClient,
	});

	const [
		loadSubscriptionControlInfo,
		{
			loading: subscriptionControlLoading,
			error: subscriptionControlError,
			data: { subscribedUserAndEntityCount } = {
				subscribedUserAndEntityCount: {},
			},
		},
	] = useLazyQuery(GET_SUBSCRIPTION_ENTITY_CONTROL, {
		client: AccountClient,
	});

	const [
		loadPackage,
		{
			loading: packageLoading,
			error: packageError,
			data: { getPackageByAccount } = { getPackageByAccount: [] },
		},
	] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
		client: AccountClient,
	});

	const [
		loadCurrency,
		{
			loading: currencyLoading,
			error: currencyError,
			data: { getCurrency } = { getCurrency: [] },
		},
	] = useLazyQuery(GET_CURRENCY, {
		client: AccountClient,
	});

	const [
		loadCountry,
		{
			loading: countryLoading,
			error: countryError,
			data: { getCountry } = { getCountry: [] },
		},
	] = useLazyQuery(COUNTRY, {
		client: AccountClient,
	});

	const [loadPic, { data: pic }] = useGetUserLogoLazyQuery({});

	useEffect(() => {
		if (loggedInUserProfile?.ID) {
			loadAccountInfos({
				variables: {
					accountID: loggedInUserProfile?.accountID,
				},
			});
			loadSubscriptionControlInfo({
				variables: {
					softwareCode: 'MENU',
					accountID: loggedInUserProfile?.accountID,
				},
			});
			loadPackage({
				variables: {
					softwareCode: 'MENU',
					accountID: loggedInUserProfile?.accountID,
				},
			});
		}
		if (loggedInUserProfile?.ID) {
			loadPic({ variables: { refID: loggedInUserProfile?.ID } });
		}
	}, [loggedInUserProfile]);

	useEffect(() => {
		if (getAccountDetail?.currencyID) {
			loadCurrency({
				variables: {
					ID: getAccountDetail?.currencyID,
				},
			});
		}
		if (getAccountDetail?.address) {
			loadCountry({
				variables: {
					name: getAccountDetail?.address?.country ?? 'Malaysia',
				},
			});
		}
	}, [getAccountDetail]);

	let userAccDetail = {
		...getAccountDetail!,
		...loggedInUserProfile!,
		companyName: getAccountDetail?.name,
		companyEmail: getAccountDetail?.email,
		contactNo: getAccountDetail?.contactNo,
		companyCurrency: getCurrency[0]?.name,
		companyCurrencyCode: getCurrency[0]?.code,
		companyCountryCode: getCountry[0]?.code?.toLowerCase(),
		mobileNo: loggedInUserProfile?.contactNo!,
		maxOutlet: subscribedUserAndEntityCount?.entityControl?.noOfEntity,
		imageURL: pic?.logoView?.fileURL,
	};
	console.log(userAccDetail);

	// USER INFO
	localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail));

	// INDIVIDUAL PERMISSION
	localForage.setItem('permission', getRolePermission);
	localForage.setItem('POSPermission', getPOSPermission);

	const { permission } = usePermissionChecker();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	useEffect(() => {
		if (redirect === true && !accountLoading) {
			let outletRoute;
			if (getOutlet?.length === 0) {
				outletRoute = `/menu/outlet-app/outlet-setting`;
			} else {
				outletRoute = `/menu/outlet-app/${outletID}/table/grid`;
			}
			console?.log(outletRoute, 'outletRoute');

			// Redirect to the home page after the loading is complete
			// const newWindow = window.open(`${outletRoute}`);
			window.location.href = `${outletRoute}`;
			// setNewPage(true);
			// // Check if the window opened successfully to avoid issues with pop-up blockers
			// if (newWindow) {
			// 	// Close the current window
			// 	window.close();
			// } else {
			// 	// Handle the case where the new window failed to open (e.g., due to pop-up blockers)
			// 	console.error('New window failed to open, pop-up blocked');
			// }
		}
	}, [loading, accountLoading, packageLoading, redirect]);

	console?.log(redirect, 'redirect');

	return (
		<>
			{loading && <Loading />}
			{accountLoading && <Loading />}
			{packageLoading && <Loading />}
			{/* <MainHeader
				mainBtn="menu"
				onClick={handleDrawer}
				smTitle="MenuX"
				title={user?.companyName}
				routeSegments={[
					{
						name: 'Integrated Restaurant Management Solution',
						current: true,
					},
				]}
			/> */}
			<ContentWrapper>
				<div>
					<p>Redirecting to home...</p>
				</div>
			</ContentWrapper>
		</>
	);
};
