import accountjournalsRoutes from './AccountJournalsModule/AccountJournalRoutes';
import billListingRoutes from './BillListing/BillListingRoutes';
import businessInsightRoutes from './BusinessInsights/BusinessInsightsRoutes';
import commonSettingRoutes from './CommonSettings/CommonSettingRoutes';
import consolidatedeInvoiceRoutes from './ConsolidatedEInvoice/ConsolidatedEInvoiceRoutes';
import generalSettingRoutes from './GeneralSettings/GeneralSettingRoutes';
import tableGridRoutes from './Home/TableGrid/TableGridRoute';
import outletSettingsRoutes from './OutletSettings/OutletSettingsRoutes';
import reservationRoutes from './Reservation/ReservationRoutes';
import utilityRoutes from './Utility/UtilityRoutes';

const outletRoutes = [
	...tableGridRoutes,
	...reservationRoutes,
	...commonSettingRoutes,
	...generalSettingRoutes,
	...businessInsightRoutes,
	...outletSettingsRoutes,
	...utilityRoutes,
	...billListingRoutes,
	...accountjournalsRoutes,
	...consolidatedeInvoiceRoutes,
];

export default outletRoutes;
