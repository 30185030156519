import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { useGetOutletQuery } from 'generated/graphql';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import ReportViewer, { menuxReportUrl } from '../ReportViewer';

export const MenuItemsMasterFileListing = () => {
	const history = useHistory();
	const { outletID }: any = useParams();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const accountID = user.accountID;
	const loginID = user.ID;

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	let reporturl =
		'MenuItemsMasterFileListingReport' +
		'?accountid=' +
		accountID +
		'&outletid=' +
		outletID +
		'&loginid=' +
		loginID;

	return (
		<>
			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/${outletID}/digital-reporting`)
				}
				mainBtn="back"
				smTitle="Business Insights"
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Digital Reporting' },
					{ name: 'Master Items File Listing', current: true },
				]}
			/>
			<ContentWrapper>
				{ReportViewer({
					url: reporturl,
					host: menuxReportUrl,
					// host: 'https://menuxdevreporting.ifca.asia/',
					// host: 'http://localhost:61094/',
					// host: contractReportUrl,
					excludedExportFormat: [],
				})}
			</ContentWrapper>
		</>
	);
};
