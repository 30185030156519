import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { formatDate } from 'helpers/formatDate';

export const CompanyDetail = () => {
	let history = useHistory();
	let location = useLocation();
	const state: any = location.state;
	let user = JSON.parse(localStorage.getItem('loggedInUser'));
	const CompanyDetail = state.company;

	return (
		<>
			<MainHeader
				onClick={() => history.goBack()}
				mainBtn="back"
				smTitle="HMS"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{ name: 'Company View', current: true },
				]}
			/>
			<ContentWrapper>
				<CardContents>
					<div className="content-wrap right">
						<div className="desc">Company Code</div>
						<div className="xsTitle">{CompanyDetail?.code}</div>
					</div>
					<div className="content-wrap left">
						<div className="desc">Company Name</div>
						<div className="xsTitle">{CompanyDetail?.name}</div>
					</div>
					<div className="content-wrap left">
						<div className="desc">Registration No.</div>
						<div className="xsTitle">{CompanyDetail?.companyRegNo}</div>
					</div>
					<div className="content-wrap right">
						<div className="desc">Group GST Registration No.</div>
						<div className="xsTitle">{CompanyDetail?.groupGstRegNo}</div>
					</div>
					<div className="content-wrap left">
						<div className="desc">GST Registration No.</div>
						<div className="xsTitle">{CompanyDetail?.gstNo}</div>
					</div>
					<div className="content-wrap right">
						<div className="desc">SST Registration No.</div>
						<div className="xsTitle">{CompanyDetail?.sstNo}</div>
					</div>
					<div className="content-wrap full">
						<div className="desc">TTX Registration No.</div>
						<div className="xsTitle">{CompanyDetail?.ttxNo}</div>
					</div>
					<div className="content-wrap full">
						<div className="desc">Company Description</div>
						<div className="xsTitle">{CompanyDetail?.description}</div>
					</div>
					<div className="content-wrap left">
						<div className="desc">Office Tel No.</div>
						<div className="xsTitle">{CompanyDetail?.contactNo}</div>
					</div>
					<div className="content-wrap right">
						<div className="desc">Email</div>
						<div className="xsTitle">{CompanyDetail?.email}</div>
					</div>
					<div className="content-wrap full">
						<div className="desc">Address</div>
						<div className="xsTitle text-noflow">
							{`${CompanyDetail?.address?.address}, 
              ${CompanyDetail?.address?.postCode}, 
              ${CompanyDetail?.address?.city}, 
              ${CompanyDetail?.address?.state}, 
              ${CompanyDetail?.address?.country}, `}
						</div>
					</div>

					{CompanyDetail?.isEInvIntegrated === true && (
						<>
							{/* <div className="content-wrap full">
								<div className="desc">TIN</div>
								<div className="xsTitle">{CompanyDetail?.tin}</div>
							</div> */}

							<div className="content-wrap left">
								<div className="desc">e-Invoice Start Date</div>
								<div className="xsTitle">
									{formatDate(CompanyDetail?.eInvStartDate)}
								</div>
							</div>

							<div className="content-wrap right">
								<div className="desc">e-Invoice End Date</div>
								<div className="xsTitle">
									{formatDate(CompanyDetail?.eInvEndDate)}
								</div>
							</div>

							<div className="content-wrap left">
								<div className="desc">Signature Key</div>
								<div className="xsTitle">{CompanyDetail?.eSign}</div>
							</div>

							<div className="content-wrap right">
								<div className="desc">Signature Key Expiry Date</div>
								<div className="xsTitle">
									{formatDate(CompanyDetail?.eSignExpiryDate)}
								</div>
							</div>

							<div className="content-wrap full">
								<div className="desc">Client ID</div>
								<div className="xsTitle">{CompanyDetail?.eInvClientID}</div>
							</div>

							<div className="content-wrap left">
								<div className="desc">Client Secret 1</div>
								<div className="xsTitle">
									{CompanyDetail?.eInvClientSecret || '-'}
								</div>
							</div>

							<div className="content-wrap right">
								<div className="desc">Client Secret 2</div>
								<div className="xsTitle">
									{CompanyDetail?.eInvClientSecret2 || '-'}
								</div>
							</div>
						</>
					)}
				</CardContents>
			</ContentWrapper>
		</>
	);
};
