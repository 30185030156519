import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMajorDocument,
	useCreateMajorWithGlMutation,
	useDeleteMajorWithGlMutation,
	useDragMajorMutation,
	useGetClassificationListingQuery,
	useGetEInvoiceSubQuery,
	useGetMajorQuery,
	useGetMsicCodeListingQuery,
	useGetOutletQuery,
	useUpdateMajorWithGlMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	DraggableItem,
	DragNDropList,
	DropableItem,
} from '@ifca-root/react-component/src/components/CardList/DragAndDropList';
import { Autocomplete } from '@material-ui/lab';
import { useFilterOption } from 'helpers/hooks/useFilterOption';

interface MajorProps {
	name: string;
	description: string;
	eInvClassification: string;
	classDescription: string;
	msicCode: string;
	msicDesc: string;
}

export const MajorList = (props: any) => {
	const history = useHistory();
	const location = useLocation();

	// const { [`${OUTLET}ID`]: outletID } = useParams<Record<string, any>>();
	const { outletID }: any = useParams();

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const [openDialog, setOpenDialog] = useState(false);

	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [double, setDouble] = useState(false);

	const editData = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	/// <<< QUERY >>> ///
	let user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		data: { getMajor } = { getMajor: [] },
		called: majorCalled,
		loading: majorLoading,
	} = useGetMajorQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'priority' },
	});

	const {
		data: { getEInvoiceSub } = { getEInvoiceSub: [] },
		loading: getEInvoiceSubLoading,
	} = useGetEInvoiceSubQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const {
		loading: getMsicCodeListingLoading,
		data: { getMsicCodeListing } = { getMsicCodeListing: [] },
	} = useGetMsicCodeListingQuery({
		fetchPolicy: 'network-only',
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	// useEffect(() => {
	// 	setDisplay1(
	// 		getMsicCodeListing.find(x => x?.msicCode === getOutlet[0]?.msicCode),
	// 	);
	// }, [getOutlet, getMsicCodeListing]);

	// console.log('msic', isTouch1 === false ? display1 : selectedOption1);

	// useEffect(() => {
	// 	setMsicDescA1(selectedOption1?.description);
	// 	setValue('msicDesc', selectedOption1?.description ?? display1?.description);
	// }, [selectedOption1, display1]);

	/// <<< SUBMIT MUTATION >>> ///

	const [
		createMajorWithGL,
		{ loading: createMajorLoading },
	] = useCreateMajorWithGlMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/major`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 500);
			setOpenDialog(false);
		},
	});

	/// <<< SUBMIT MUTATION >>> ///

	/// <<< UPDATE MUTATION >>> ///

	const [
		updateMajorWithGL,
		{ loading: updateMajorLoading },
	] = useUpdateMajorWithGlMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconId}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/major`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
			setOpenDialog(false);
		},
	});

	/// <<< UPDATE MUTATION >>> ///

	/// <<< DELETE MUTATION >>> ///

	const [
		deleteMajorWithGL,
		{ loading: deleteMajorWithGLLoading },
	] = useDeleteMajorWithGlMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const {
		loading: getClassificationLLoading,
		data: { getClassificationListing } = { getClassificationListing: [] },
	} = useGetClassificationListingQuery({
		fetchPolicy: 'network-only',
	});

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.test('name', 'Major name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingMajorNames?.includes(value);
				}
				return true;
			}),
		eInvClassification: yup
			.string()
			.required('e-Invoice Classification is required'),
		msicCode: yup.string().required('MSIC Code is required'),
		msicDesc: yup.string().required('MSIC Desription is required'),
	});

	const [selectedOption, setSelectedOption] = useState(null);
	const [isTouch, setIsTouch] = useState(false);
	const [display, setDisplay] = useState(null);
	const [msicDescA, setMsicDescA] = useState(null);

	const [selectedOption1, setSelectedOption1] = useState(null);
	const [isTouch1, setIsTouch1] = useState(false);
	const [display1, setDisplay1] = useState(null);
	const [msicDescA1, setMsicDescA1] = useState(null);

	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		setValue,
		getValues,
		formState,
	} = useForm<MajorProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	useEffect(() => {
		setDisplay(
			getClassificationListing.find(
				x => x?.classificationCode === menu.obj?.eInvClassification,
			),
		);
	}, [menu, getClassificationListing]);

	useEffect(() => {
		setMsicDescA(selectedOption?.description ?? display?.description);
		setValue(
			'classDescription',
			selectedOption?.description ?? display?.description,
		);
	}, [selectedOption, display, menu]);

	console?.log(menu, msicDescA1, display1, 'menu');

	useEffect(() => {
		setDisplay1(
			getMsicCodeListing.find(x => x?.msicCode === menu.obj?.msicCode),
		);
	}, [menu, getMsicCodeListing]);

	useEffect(() => {
		setMsicDescA1(selectedOption1?.description ?? display1?.description);
		setValue('msicDesc', selectedOption1?.description ?? display1?.description);
	}, [selectedOption1, display1, menu]);

	/// <<< DELETE MUTATION >>> ///

	let [initialData, setInitialData] = useState<any>();

	useEffect(() => {
		setInitialData(getMajor);
	}, [getMajor]);

	const onDragEnd = ({ source, destination }) => {
		if (destination === undefined || destination === null) return null;

		if (
			source.droppableId === destination.droppableId &&
			destination.index === source.index
		)
			return null;

		const start = source.droppableId;
		const end = destination.droppableId;

		if (start === end) {
			let testArray = initialData.filter((_, idx) => idx !== source.index);

			testArray.splice(destination.index, 0, initialData[source.index]);

			setInitialData([...testArray]);
			dragMajor({
				variables: {
					input: testArray?.map((x, i) => {
						return {
							ID: x?.ID,
							name: x?.name,
							priority: i + 1,
							outletID: outletID,
							eInvClassification: x?.eInvClassification,
						};
					}),
					outletID: outletID,
				},
			});

			return null;
		}
	};
	/// <<< DND MUTATION >>> ///
	const [
		dragMajor,
		{ loading: dragMajorLoading, error: dragMajorError },
	] = useDragMajorMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			console.log('data', data);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setOpenDialog(false);
		},
	});

	const existingMajorNames = getMajor?.map(m => m?.name);

	const onSubmit = () => {
		isEdit === false
			? createMajorWithGL({
					variables: {
						input: {
							outletID: outletID,
							name: watch('name'),
							description: '',
							eInvClassification: selectedOption?.classificationCode,
							msicCode: selectedOption1
								? selectedOption1?.msicCode
								: display1
								? display1?.msicCode
								: null,
							msicDesc: selectedOption1
								? selectedOption1?.description
								: display1
								? display1?.description
								: null,
						},
					},
					refetchQueries: [
						{
							query: GetMajorDocument,
							variables: { outletID: outletID, orderByAsc: 'priority' },
						},
					],
			  })
			: updateMajorWithGL({
					variables: {
						input: {
							ID: menu?.ID,
							outletID: outletID,
							name: watch('name'),
							description: '',
							eInvClassification: selectedOption
								? selectedOption?.classificationCode
								: display
								? display?.classificationCode
								: null,
							msicCode: selectedOption1
								? selectedOption1?.msicCode
								: display1
								? display1?.msicCode
								: null,
							msicDesc: selectedOption1
								? selectedOption1?.description
								: display1
								? display1?.description
								: null,
						},
					},
					refetchQueries: [
						{
							query: GetMajorDocument,
							variables: { outletID: outletID, orderByAsc: 'priority' },
						},
					],
			  });
		clearErrors();
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		clearErrors();
	};

	const { filterOptions } = useFilterOption();

	return (
		<>
			{majorLoading && <Loading />}
			{outletLoading && <Loading />}
			{/* {submitMajorLoading && <Loading />} */}

			{updateMajorLoading && <Loading />}
			{deleteMajorWithGLLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Major & Family', current: true },
				]}
			/>
			<ContentWrapper>
				<MsgAlert
					//   icon={<InfoOutlined />}
					message={'Drag & drop to arrange sequence. '}
				/>
				<DragNDropList onDragEnd={onDragEnd}>
					<DropableItem droppableId={'major_list'}>
						<List className="core-list">
							{!majorLoading &&
							!outletLoading &&
							(getMajor === undefined || getMajor?.length === 0) ? (
								<EmptyList
									title="No Record found"
									subtitle="Please add major to continue"
								/>
							) : (
								initialData?.map((el: any, index: number) => (
									// <ListItem>
									<DraggableItem
										// style={{ padding: 0 }}
										id={el.ID}
										index={index}
										draggableId={el.ID}
									>
										<div style={{ paddingRight: '6px' }}></div>
										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{el?.name}</span>
													<span className="xxTitle highlight-text text-separator"></span>
												</>
											}
											secondary={
												<>
													<span className="desc">
														<span className="desc">
															Total Menu Items: {el?.family?.length}
														</span>
													</span>
												</>
											}
										/>

										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="more"
												aria-controls="menu-list"
												aria-haspopup="true"
												onClick={e => handleClick(e, el.ID, index, el)}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</DraggableItem>
									// </ListItem>
								))
							)}
						</List>
					</DropableItem>
				</DragNDropList>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							history.push(
								`/menu/outlet-app/outlet-setting/${outletID}/major/${menu.ID}/family`,
							);
						}}
					>
						<span className="">Family</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<Box mt={1}>
				<CommonDialogV2
					fullWidth={true}
					open={openDialog}
					onClose={() => {
						handleCloseDialog();
						setSelectedOption(null);
						setIsTouch(false);
						setDisplay(null);
						setDisplay1(null);
					}}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												{isEdit ? (
													<div className="title flex-space color-primary-orange">
														Edit Major
													</div>
												) : (
													<div className="title flex-space color-primary-orange">
														New Major
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<div className="content-container">
									<Controller
										as={TextField}
										defaultValue={isEdit === true ? menu?.obj?.name : null}
										name="name"
										label="Title"
										autoComplete="off"
										multiline={true}
										required
										fullWidth
										ref={register}
										control={control}
										helperText={errors?.name?.message}
										error={errors?.name ? true : false}
										onInput={() => {
											setDouble(false);
										}}
									/>
								</div>
								<div className="content-container">
									{getEInvoiceSub?.length > 0 && (
										<>
											<Autocomplete
												options={getClassificationListing}
												fullWidth
												getOptionLabel={option => option?.classificationCode}
												filterOptions={(options, state) =>
													filterOptions(options, state, [
														'classificationCode',
														'description',
													])
												}
												value={isTouch === false ? display : selectedOption}
												onChange={(event, value) => {
													setIsTouch(true);
													setSelectedOption(value);
												}}
												renderInput={params => (
													<TextField
														{...params}
														label="e-Invoice Classification"
														variant="standard"
														name="eInvClassification"
														helperText={
															!selectedOption?.id && isTouch === true
																? 'e-Invoice Classification is required'
																: null
														}
														error={
															!selectedOption?.id && isTouch === true
																? true
																: false
														}
													/>
												)}
												renderOption={option => (
													<div>
														<div
															className="fw-normal"
															style={{ fontSize: '13px' }}
														>
															<b>{option.classificationCode}</b>
														</div>
														<div
															className="fw-normal"
															style={{ fontSize: '13px' }}
														>
															{option.description ? option.description : '-'}{' '}
														</div>
													</div>
												)}
											/>

											<Controller
												as={TextField}
												name="classDescription"
												label="Classification Description"
												defaultValue={display?.description}
												autoComplete="off"
												multiline={true}
												fullWidth
												value={msicDescA}
												disabled
												ref={register}
												InputLabelProps={
													msicDescA || display ? { shrink: true } : {}
												}
												control={control}
												margin="dense"
											></Controller>

											<Autocomplete
												options={getMsicCodeListing}
												fullWidth
												getOptionLabel={option => option?.msicCode}
												value={isTouch1 === false ? display1 : selectedOption1}
												onChange={(event, value) => {
													setIsTouch1(true);
													setSelectedOption1(value);
												}}
												filterOptions={(options, state) =>
													filterOptions(options, state, [
														'msicCode',
														'description',
													])
												}
												renderInput={params => (
													<TextField
														{...params}
														label="MSIC Code"
														variant="standard"
														name="msicCode"
														helperText={
															!selectedOption1?.id && isTouch1 === true
																? 'MSIC Code is required'
																: null
														}
														error={
															!selectedOption1?.id && isTouch1 === true
																? true
																: false
														}
													/>
												)}
												renderOption={option => (
													<div>
														<div
															className="fw-normal"
															style={{ fontSize: '13px' }}
														>
															<b>{option.msicCode}</b>
														</div>
														<div
															className="fw-normal"
															style={{ fontSize: '13px' }}
														>
															{option.description ? option.description : '-'}{' '}
														</div>
													</div>
												)}
											/>

											<Controller
												as={TextField}
												name="msicDesc"
												label="MSIC Description"
												defaultValue={display1?.description}
												autoComplete="off"
												multiline={true}
												fullWidth
												value={msicDescA1}
												disabled
												ref={register}
												InputLabelProps={
													msicDescA1 || display1 ? { shrink: true } : {}
												}
												control={control}
												margin="dense"
											></Controller>
										</>
									)}
								</div>
							</>
						),

						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											handleCloseDialog();
											setSelectedOption(null);
											setIsTouch(false);
											setDisplay(null);
											setDisplay1(null);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Save',
									props: {
										variant: 'contained',
										// color: existingMajorNames?.includes(watch('name'))
										// 	? 'inherit'
										// 	: 'primary',
										// form: 'submit-form',
										// onClick: () => {
										// 	if (!existingMajorNames?.includes(watch('name'))) {
										// 		handleSubmit(onSubmit)();
										// 	}
										// },
										disabled: double,

										onClick: () => {
											if (errors?.name ? false : true) {
												onSubmit();
												setDouble(true);
											}
										},

										color: 'primary',
									},
								},
							],
						},
					}}
				/>
			</Box>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, orderByAsc: 'priority' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteMajorWithGL}
				refetchDocument={GetMajorDocument}
			/>

			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
