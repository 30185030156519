import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { getAccessToken } from '../../../AccessToken';
import AppContext from '../Store/AppContext';

export const PrivateRoute = ({ children, ...rest }) => {
	const { globalState, dispatch } = useContext(AppContext as any);
	let history = useHistory();
	const routing: any = history?.location?.pathname;

	let bill3rdPartyRoute = routing.replace(/\/([a-f0-9-]{36})/g, '/');

	bill3rdPartyRoute = bill3rdPartyRoute.replace(/\/+/g, '/');

	let fetchBill3rdPartyRoute = bill3rdPartyRoute.replace(/\/$/, '');

	const AccessToken = getAccessToken();

	console?.log(routing, fetchBill3rdPartyRoute, 'fetchBill3rdPartyRoute');

	return (
		<Route
			{...rest}
			render={({ location }) =>
				AccessToken || fetchBill3rdPartyRoute === '/menux/outlet/bill-qr' ? (
					children
				) : (
					<Redirect
						to={{
							pathname: `/login`,
							state: { from: location },
						}}
					/>
				)
			}
		/>
	);
};
