import { Divider, Grid } from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { OrderDiscountStatus, PaymentClass } from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { amtStr } from 'helpers/numFormatter';
import React, { useState } from 'react';
import { TaxSchemeComponent } from './TaxSchemeComponent';
import { toDataURL } from 'qrcode';
import { SSTSummaryComponent } from './SSTSummary';

const dateFormat = require('dateformat');

export const BillFooterReceipt = (props: any) => {
	const {
		total,
		discount,
		discountCode,
		subtotal,
		serviceCharge,
		tax,
		mode,
		query,
		latestPayment,
		chargeInfo1,
		chargeInfo2,
		currencyCode,
		rounding,
		outletID,
		order,
		officer,
		staff,
	} = props;

	const handleCardType = cardType => {
		switch (cardType) {
			case 'VISA_CARD':
				return 'VISA';
			case 'MASTERCARD':
				return 'MASTERCARD';
			case 'AMERICAN_EXPRESS_CARD':
				return 'AMERICAN EXPRESS';
			case 'DINERS_CARD':
				return 'DINERS';
			case 'JCB_CARD':
				return 'JCB';
			case 'UNIONPAY_CARD':
				return 'UNIONPAY';
			case 'DEBIT_CARD':
				return 'DEBIT';
			default:
				return 'Credit';
		}
	};

	const activeOrderDiscount = query[0]?.order?.orderDiscount?.filter(
		y => y?.status === OrderDiscountStatus?.Active,
	)[0];

	const OCENT = discountCode?.filter(
		x => x?.ID === activeOrderDiscount?.discountID,
	)[0]?.discountType;

	const displayOCENT =
		OCENT === 'OFFICER_CHECK'
			? 'Officer Check'
			: OCENT === 'ENTERTAINMENT'
			? 'Entertainment'
			: OCENT === 'DISCOUNT'
			? 'Discount'
			: 'Promo';

	let roundAmount = 0;
	latestPayment?.map(x => {
		roundAmount += x?.roundingAmount;
	});
	const company = query[0]?.outlet?.company;
	const qrCodeUrl = query[0]?.qrCodeUrl;

	const Einvoice =
		query[0]?.einvoice_qr_url !== null && query[0]?.is_einvoice === true;

	const eInvoiceQr = () => {
		const condition =
			query[0]?.is_einvoice === true &&
			company?.isEInvIntegrated === true &&
			dateFormat(query[0]?.createdTs, 'yyyy-mm-dd') >=
				dateFormat(company?.eInvStartDate, 'yyyy-mm-dd') &&
			dateFormat(query[0]?.createdTs, 'yyyy-mm-dd') <=
				dateFormat(company?.eInvEndDate, 'yyyy-mm-dd') &&
			qrCodeUrl !== null;

		console?.log(condition, qrCodeUrl, 'condition');

		const result =
			condition === true ? (
				// <td>
				<>
					<div className="dialog-dynamic-content">
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space xsTitle"
									style={{
										alignSelf: 'center',
										fontSize: '16px',
										color: '#212121',
										textAlign: 'center',
									}}
								>
									<img
										src={qrCodeUrl}
										alt="QR Code"
										style={{ width: '85px', display: 'inline-block' }}
									/>
								</div>
							</div>
						</div>
					</div>
					<div style={{ width: '100%', lineHeight: 1 }}>
						<span
							style={{
								fontSize: '9px',
								display: 'flex',
								justifyContent: 'center',
								textAlign: 'center',
							}}
						>
							Scan within 72 hours if
						</span>
						<span
							style={{
								fontSize: '9px',
								display: 'flex',
								justifyContent: 'center',
								textAlign: 'center',
							}}
						>
							e-Invoice is required
						</span>
					</div>
				</>
			) : null;
		return result;
	};

	console?.log(query, 'query');

	return (
		<>
			<div className="dialog-dynamic-content" style={{ marginTop: '10px' }}>
				<div className="session">
					<div className="flex session">
						<div
							className="flex-space mdDesc"
							style={{
								alignSelf: 'center',
								fontSize: '16px',
								color: '#212121',
							}}
						>
							{'Sub-Total'}
						</div>
						<div
							className="xsTitle rightText"
							style={{ fontSize: '16px', color: '#212121' }}
						>
							{currencyCode + amtStr(subtotal)}
						</div>
					</div>
				</div>
			</div>

			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div
							className="flex-space mdDesc"
							style={{
								alignSelf: 'center',
								fontSize: '16px',
								color: '#212121',
							}}
						>
							{activeOrderDiscount ? `Discount: ` : `Discount `}
							{query[0]?.order?.orderDiscount?.filter(
								y => y?.status === OrderDiscountStatus?.Active,
							)?.length > 0 &&
								`${
									discountCode?.filter(
										x => x?.ID === activeOrderDiscount?.discountID,
									)[0]?.code
								}`}
						</div>
						<div
							className="xsTitle rightText"
							style={{ fontSize: '16px', color: '#212121' }}
						>
							{`(${currencyCode}${amtStr(discount)})`}
						</div>
					</div>
				</div>
			</div>

			<div className="dialog-dynamic-content">
				<div className="session">
					<div className="flex session">
						<div
							className="flex-space mdDesc"
							style={{
								alignSelf: 'center',
								fontSize: '16px',
								color: '#212121',
								height: '17px',
							}}
						>
							{'Rounding'}
						</div>
						<div
							className="xsTitle rightText"
							style={{ fontSize: '16px', color: '#212121' }}
						>
							{mode === 'bill-settlement'
								? rounding < 0
									? '(' + currencyCode + Math.abs(rounding).toFixed(2) + ')'
									: currencyCode + amtStr(rounding)
								: roundAmount < 0
								? '(' + currencyCode + Math.abs(roundAmount).toFixed(2) + ')'
								: currencyCode + amtStr(roundAmount)}
						</div>
					</div>
				</div>
			</div>

			<Divider style={{ display: 'flex', width: '100%' }} />

			<div
				className="dialog-dynamic-content"
				style={{ paddingTop: '5px', paddingBottom: '10px' }}
			>
				<div className="session">
					<div className="flex session">
						<div
							className="flex-space xsTitle"
							style={{
								alignSelf: 'center',
								fontSize: '16px',
								paddingBottom: '5px',
								color: '#212121',
							}}
						>
							{'Total'}
						</div>
						<div className="rightText">
							<div
								className={
									mode == 'bill-settlement'
										? 'bill-settlement-number'
										: 'highlight-text'
								}
								style={{
									fontSize: '16px',
									color: '#212121',
									paddingBottom: '5px',
								}}
							>
								{`${currencyCode}${amtStr(
									new BigNumber(Math.abs(total))
										.plus(roundAmount ?? 0)
										.toNumber(),
								)}`}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Divider style={{ display: 'flex', width: '100%' }} />

			<TaxSchemeComponent
				outletID={outletID}
				taxSchemeID={null}
				currencyCode={currencyCode}
				type={'receipt'}
				taxSchemeInfo={order?.taxSchemeInfo}
				taxSchemeFooterInfo={order?.taxSchemeFooterInfo}
				takeaway={false}
				orderID={order?.ID}
			/>

			<Divider style={{ display: 'flex', width: '100%', marginTop: '10px' }} />

			{mode === 'bill-settlement' && (
				<>
					<div
						className="dialog-dynamic-content"
						style={{ paddingBlock: '10px' }}
					>
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space mdDesc"
									style={{ alignSelf: 'center', color: '#212121' }}
								>
									{'Name : ___________________________'}
								</div>
							</div>
						</div>
					</div>
					<div
						className="dialog-dynamic-content"
						style={{ paddingBlock: '10px' }}
					>
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space mdDesc"
									style={{ alignSelf: 'center', color: '#212121' }}
								>
									{'Room/ACC : ________________________'}
								</div>
							</div>
						</div>
					</div>
					<div
						className="dialog-dynamic-content"
						style={{ paddingBlock: '10px' }}
					>
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space mdDesc"
									style={{ alignSelf: 'center', color: '#212121' }}
								>
									{'Signature : _________________________'}
								</div>
							</div>
						</div>
					</div>
				</>
			)}

			{mode == 'void-bill' || mode == 'bill-settlement' ? null : (
				<>
					<div className="dialog-dynamic-content" style={{ marginTop: '5px' }}>
						<div className="session">
							<div className="flex session">
								<div
									className="flex-space xsTitle"
									style={{
										alignSelf: 'center',
										fontSize: '16px',
										color: '#212121',
									}}
								>
									{'Payment'}
								</div>
							</div>
						</div>
					</div>
					{latestPayment?.map(x => {
						return (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div
												className="flex-space mdDesc"
												style={{
													alignSelf: 'center',
													fontSize: '16px',
													color: '#212121',
												}}
											>
												{x?.paymentClass === PaymentClass.UnsettledBill
													? 'Unsettled Bill'
													: x?.paymentClass === PaymentClass.Credit
													? handleCardType(x?.creditCardType)
													: x?.paymentClass === PaymentClass.Online
													? 'Online Payment'
													: x?.paymentClass === PaymentClass.NonGuest
													? 'Non Guest'
													: x?.paymentClass === PaymentClass.SuspendFolio
													? 'Suspend Folio'
													: x?.paymentClass === PaymentClass.BankTt
													? 'Bank TT'
													: toProperCase(x?.paymentClass)}
											</div>
											<div
												className="xsTitle rightText"
												style={{ fontSize: '16px', color: '#212121' }}
											>
												{x?.paymentClass === PaymentClass.UnsettledBill
													? currencyCode + amtStr(x?.amount)
													: currencyCode + amtStr(x?.receivedAmount)}
											</div>
										</div>
									</div>
								</div>

								{x?.paymentClass === PaymentClass.Room ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														height: '17px',
													}}
												>
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.hotelGuestInfo?.RoomNo && (
														<span>{`${x?.hotelGuestInfo?.RoomNo} | `}</span>
													)}
													{x?.hotelGuestInfo?.FullName &&
													x?.hotelGuestInfo?.FullName?.length <= 25 ? (
														<span
															className="highlight-text flex-space"
															style={{ color: '#ff9800' }}
														>
															{`${x?.hotelGuestInfo?.FullName}`}
														</span>
													) : (
														<Grid
															item
															xs={12}
															className="highlight-text flex-space"
															style={{ color: '#ff9800', marginTop: '5px' }}
														>
															{`${x?.hotelGuestInfo?.FullName}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.NonGuest ||
								x?.paymentClass === PaymentClass.SuspendFolio ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														height: '17px',
													}}
												>
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.hotelGuestInfo?.FullName &&
													x?.hotelGuestInfo?.FullName?.length <= 25 ? (
														<span
															className="highlight-text"
															style={{ color: '#ff9800' }}
														>
															{`${x?.hotelGuestInfo?.FullName}`}
														</span>
													) : (
														<Grid
															item
															xs={12}
															className="highlight-text"
															style={{ color: '#ff9800', marginTop: '5px' }}
														>
															{`${x?.hotelGuestInfo?.FullName}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Debtor ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														height: '17px',
													}}
												>
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.hotelGuestInfo?.GuestType && (
														<span>{`${x?.hotelGuestInfo?.GuestType} | `}</span>
													)}
													{x?.hotelGuestInfo?.FullName &&
													x?.hotelGuestInfo?.FullName?.length <= 25 ? (
														<span
															className="highlight-text"
															style={{ color: '#ff9800' }}
														>
															{`${x?.hotelGuestInfo?.FullName}`}
														</span>
													) : (
														<Grid
															item
															xs={12}
															className="highlight-text"
															style={{ color: '#ff9800', marginTop: '5px' }}
														>
															{`${x?.hotelGuestInfo?.FullName}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Staff ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														height: '17px',
													}}
												>
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{staff[0]?.designation && (
														<span>{`${staff[0]?.designation} | `}</span>
													)}
													{staff[0]?.name && staff[0]?.name?.length <= 25 ? (
														<span
															className="highlight-text"
															style={{ color: '#ff9800' }}
														>
															{`${staff[0]?.name}`}
														</span>
													) : (
														<Grid
															item
															xs={12}
															className="highlight-text"
															style={{ color: '#ff9800', marginTop: '5px' }}
														>
															{`${staff[0]?.name}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Officer ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														height: '17px',
													}}
												>
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{officer[0]?.designation && (
														<span>{`${officer[0]?.designation} | `}</span>
													)}
													{officer[0]?.name &&
													officer[0]?.name?.length <= 25 ? (
														<span
															className="highlight-text"
															style={{ color: '#ff9800' }}
														>
															{`${officer[0]?.name}`}
														</span>
													) : (
														<Grid
															item
															xs={12}
															className="highlight-text"
															style={{ color: '#ff9800', marginTop: '5px' }}
														>
															{`${officer[0]?.name}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.BankTt ||
								x?.paymentClass === PaymentClass.Cheque ||
								x?.paymentClass === PaymentClass.Online ||
								x?.paymentClass === PaymentClass.Skipper ||
								x?.paymentClass === PaymentClass.Member ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
													}}
												>
													Remark:
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.remark ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Voucher ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="flex-space mdDesc"
													style={{
														alignSelf: 'center',
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
													}}
												>
													Code:
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.referenceNo ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Others ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="flex-space mdDesc"
													style={{
														alignSelf: 'center',
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
													}}
												>
													Remarks:
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.referenceNo ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Credit ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="flex-space mdDesc"
													style={{
														alignSelf: 'center',
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
													}}
												>
													Card ref:
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{`****${x?.cardNo?.slice(-4)}` ?? '-'}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Credit &&
								x?.hotelGuestInfo?.CompanyName !== undefined ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="mdDesc flex-space"
													style={{
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														height: '17px',
													}}
												>
													<span>{`Charged to: `}</span>
												</div>
												<div
													className="xsTitle rightText"
													style={{
														fontSize: '16px',
														color: '#212121',
														paddingRight: '20px',
													}}
												>
													{x?.hotelGuestInfo?.DebtorAccount && (
														<span>{`${x?.hotelGuestInfo?.DebtorAccount} | `}</span>
													)}
													{x?.hotelGuestInfo?.CompanyName &&
													x?.hotelGuestInfo?.CompanyName?.length <= 25 ? (
														<span
															className="highlight-text"
															style={{ color: '#ff9800' }}
														>
															{`${x?.hotelGuestInfo?.CompanyName}`}
														</span>
													) : (
														<Grid
															item
															xs={12}
															className="highlight-text"
															style={{ color: '#ff9800', marginTop: '5px' }}
														>
															{`${x?.hotelGuestInfo?.CompanyName}`}
														</Grid>
													)}
												</div>
											</div>
										</div>
									</div>
								) : null}

								{x?.paymentClass === PaymentClass.Cash &&
								x?.changeAmount > 0 ? (
									<div
										className="dialog-dynamic-content"
										style={{ marginBottom: '5px', paddingLeft: '10px' }}
									>
										<div className="session">
											<div className="flex session">
												<div
													className="flex-space mdDesc"
													style={{
														alignSelf: 'center',
														fontSize: '16px',
														paddingBlock: '2px',
														color: '#212121',
														fontStyle: 'italic',
													}}
												>
													{'Change'}
												</div>
												<div
													className="xsTitle rightText"
													style={{
														color: '#212121',
														fontSize: '16px',
														fontStyle: 'italic',
													}}
												>
													{currencyCode + amtStr(x?.changeAmount)}
												</div>
											</div>
										</div>
									</div>
								) : null}
							</>
						);
					})}

					{latestPayment?.length === 0 ? (
						<div className="dialog-dynamic-content">
							<div className="session">
								<div className="flex session">
									<div
										className="flex-space mdDesc"
										style={{
											alignSelf: 'center',
											fontSize: '16px',
											color: '#212121',
										}}
									>
										{displayOCENT}
									</div>
									<div
										className="xsTitle rightText"
										style={{ fontSize: '16px', color: '#212121' }}
									>
										{currencyCode + amtStr(0)}
									</div>
								</div>
							</div>
						</div>
					) : null}
				</>
			)}

			<Divider style={{ display: 'flex', width: '100%', marginTop: '10px' }} />

			<SSTSummaryComponent
				outletID={outletID}
				orderID={order?.ID}
				accountID={JSON.parse(localStorage.getItem('loggedInUser'))?.accountID}
			/>

			{Einvoice ? eInvoiceQr() : null}

			{mode == 'void-bill' || mode == 'bill-settlement' ? null : (
				<div
					className="dialog-dynamic-content"
					style={{ marginTop: '30px', marginBottom: '5px' }}
				>
					<div className="session">
						<div className="flex session">
							<div
								className="flex-space xsTitle text-noflow"
								style={{
									alignSelf: 'center',
									fontSize: '14px',
									color: '#212121',
									textAlign: 'center',
									height: '17px',
								}}
							>
								{'Thank you & we hope to see you again soon.'}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
