import React from 'react';
import moment from 'moment';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
	useGetDefaultPaymentQuery,
	useGetPatronQuery,
} from 'generated/graphql';
import { useParams } from 'react-router';

interface Props {
	setFilterValue: any;
	filterValue: any;
	refetch: any;
	open: boolean;
	close: () => void;
	// paymentTypeList?: any;
	// customerTypeList?: any;
}

export const FilterDialog = ({
	setFilterValue,
	filterValue,
	refetch,
	open,
	close,
}: // paymentTypeList,
// customerTypeList,
Props) => {
	const { outletID } = useParams<any>();

	const onSubmit = () => {
		window.scrollTo(0, 0);
		let filter = {};

		filter['startDate'] = moment(filterValue?.startDate)?.isValid()
			? filterValue?.startDate
			: null;

		filter['endDate'] = moment(filterValue?.endDate)?.isValid()
			? filterValue?.endDate
			: null;

		filter['docNo'] = filterValue?.docNo !== '' ? filterValue?.docNo : null;

		filter['docType'] =
			filterValue?.docType !== '' ? filterValue?.docType : null;

		refetch(filter);
		localStorage.setItem('filterArgs', JSON.stringify(filter));
	};

	return (
		<CommonDialog
			fullWidth={true}
			open={open}
			onClose={close}
			sections={{
				header: {
					dynamic: (
						<div className="">
							<div className="dialog-dynamic-content">
								<div
									className="session"
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<div className="title flex-space">Advance Search</div>
									<div>
										<Button
											variant="outlined"
											size="small"
											disableElevation
											onClick={() => {
												window.scrollTo(0, 0);
												localStorage.removeItem('filterArgs');
												const initialValue = () => {
													return {
														startDate: null,
														endDate: null,
														docNo: '',
														docType: '',
													};
												};
												const resetValue = initialValue();
												setFilterValue(resetValue);
											}}
											startIcon={<Loop />}
											style={{
												color: '#ff9800',
												backgroundColor: 'white',
												borderColor: '#ff9800',
												borderStyle: '10px solid',
											}}
										>
											Reset
										</Button>
									</div>
								</div>
							</div>
						</div>
					),
				},
				body: () => (
					//
					// <CardContents
					// 	devFullWidth={true}
					// 	style={{ boxShadow: 'none', padding: '0px 0px 0px 0px' }}
					// >
					<form onSubmit={() => onSubmit()}>
						<Box display="flex" width="100%">
							<Box pr="6px" width="50%">
								<KeyboardDatePicker
									value={filterValue?.startDate}
									margin="dense"
									autoOk
									label="Start Date"
									className="left date-icon"
									name="startDate"
									onChange={e =>
										setFilterValue({
											...filterValue,
											startDate: moment(e)
												.startOf('day')
												.toISOString(),
										})
									}
									format="dd/MM/yyyy"
									fullWidth
								/>
							</Box>

							<Box pr="6px" width="50%">
								<KeyboardDatePicker
									value={filterValue?.endDate}
									margin="dense"
									autoOk
									label="End Date"
									className="right date-icon"
									name="endDate"
									onChange={e => {
										setFilterValue({
											...filterValue,
											endDate: moment(e)
												.endOf('day')
												.toISOString(),
										});
									}}
									// minDate={filterValue.endDate}
									format="dd/MM/yyyy"
									fullWidth
								/>
							</Box>
						</Box>

						<TextField
							label="Doc No"
							fullWidth
							value={filterValue?.docNo || ''}
							margin="dense"
							name="docNo"
							onChange={e => {
								setFilterValue({
									...filterValue,
									docNo: e.target.value,
								});
							}}
						/>

						<TextField
							label="Doc Type"
							fullWidth
							value={filterValue?.docType || ''}
							margin="dense"
							name="docType"
							onChange={e => {
								setFilterValue({
									...filterValue,
									docType: e.target.value,
								});
							}}
						/>
					</form>
					// </CardContents>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => {
									close();
								},
								variant: 'contained',
								color: 'primary',
							},
						},
						{
							displayText: 'Confirm',
							props: {
								onClick: () => {
									onSubmit();
									close();
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);
};
