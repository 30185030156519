import React from 'react';
import { CompanyList } from './CompanyList';
import { CompanyForm } from './CompanyForm';
import { CompanyDetail } from './CompanyDetail';

const companyListingRoutes = [
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/common-setting/company-list',
		},
		component: <CompanyList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/company-list/add`,
		},
		component: <CompanyForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/company-list/:companyID/edit`,
		},
		component: <CompanyForm mode="edit" />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/common-setting/company-list/:companyID/view',
		},
		component: <CompanyDetail />,
	},
];

export default companyListingRoutes;
