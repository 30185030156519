import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetGeneralSettingSummaryLazyQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
	AppointedOfficerIcon,
	AuthorisedStaffIcon,
	RoundingPolicy,
	ThirdPartyInterfaceSetupIcon,
	UserAccessSecurity,
	TaxSetup,
} from '../../../assets';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

const details = (description: string, highlighted: any) => {
	return (
		<span className="desc">
			{' '}
			{description} <span className="highlight-text">{highlighted}</span>
		</span>
	);
};

export const GeneralSettingSubmenu = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const outletID = localStorage.getItem('latestOutlet');

	const { handlePermHidden } = usePermissionChecker();

	const [
		loadGeneralSettingSummary,
		{
			called: generalSettingSummaryCalled,
			loading: generalSettingSummaryLoading,
			data: generalSettingSummaryData,
		},
	] = useGetGeneralSettingSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!generalSettingSummaryCalled) {
			loadGeneralSettingSummary();
		}
	}, [generalSettingSummaryCalled]);

	const officerCount = generalSettingSummaryData?.officerCount;
	const staffCount = generalSettingSummaryData?.staffCount;
	const userCount = generalSettingSummaryData?.userCount;
	const roundingPolicyDate = generalSettingSummaryData?.roundingPolicyDate;
	const uomDate = generalSettingSummaryData?.uomDate;
	const uomConversionDate = generalSettingSummaryData?.uomConversionDate;
	const taxSetupDate = generalSettingSummaryData?.taxDate;
	const taxSchemeDate = generalSettingSummaryData?.taxSchemeDate;
	const eInvoicePolicyDate = generalSettingSummaryData?.eInvoicePolicyDate;

	const menuList = [
		{
			name: 'Appointed Officer',
			icon: AppointedOfficerIcon,
			description: details('No. of Appointed Officer:', officerCount),
			path: `/menu/outlet-app/general-setting/officer`,
			permission: PosPermission.GeneralSettingAppointedOfficerShow,
		},
		{
			name: 'Authorised Staff',
			icon: AuthorisedStaffIcon,
			description: details('No. of Authorised Staff:', staffCount),
			path: `/menu/outlet-app/general-setting/employee`,
			permission: PosPermission.GeneralSettingAuthorisedStaffShow,
		},
		{
			name: 'Access Security',
			icon: UserAccessSecurity,
			description: details('No. of User:', userCount),
			path: `/menu/outlet-app/general-setting/user-access`,
			permission: PosPermission.GeneralSettingAccessSecurityShow,
		},
		{
			name: 'Rounding Policy',
			icon: RoundingPolicy,
			description: details('Last updated on ', formatDate(roundingPolicyDate)),
			path: `/menu/outlet-app/general-setting/rounding-policy`,
			permission: PosPermission.GeneralSettingRoundingPolicyUpdate,
		},
		{
			name: 'UOM',
			icon: ThirdPartyInterfaceSetupIcon,
			description: details('Last updated on ', formatDate(uomDate)),
			path: `/menu/outlet-app/general-setting/uom`,
			permission: PosPermission.GeneralSettingUomShow,
		},
		{
			name: 'UOM Conversion',
			icon: ThirdPartyInterfaceSetupIcon,
			description: details('Last updated on ', formatDate(uomConversionDate)),
			path: `/menu/outlet-app/general-setting/uom-exchange`,
			permission: PosPermission.GeneralSettingUomConversionShow,
		},
		{
			name: 'e-Invoice Policy',
			icon: ThirdPartyInterfaceSetupIcon,
			description: details('Last updated on ', formatDate(eInvoicePolicyDate)),
			path: `/menu/outlet-app/general-setting/e-invoice-policy`,
			permission: PosPermission.GeneralSettingEInvoicePolicyShow,
		},
	];
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<>
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[{ name: 'General Settings', current: true }]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{menuList?.map((v, i) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: v.permission,
							})
						)
							return (
								<>
									<ListItem onClick={() => history.push(v.path)}>
										{/* <SettingsIcon color="primary" style={{ paddingRight: '12px' }} /> */}
										<ListItemIcon>
											<img
												className="icon-svg"
												style={{ paddingRight: '10px' }}
												src={v.icon}
												alt="drawer-icon"
											/>
										</ListItemIcon>
										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{v?.name}</span>
												</>
											}
											secondary={<>{v.description}</>}
										/>
										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="delete">
												<KeyboardArrowRight
													onClick={() => history.push(v.path)}
												/>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
					})}
				</List>
			</ContentWrapper>
		</>
	);
};
