import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons';
import { NoImage } from 'assets';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useCompanyUpdateStatusMutation,
	useGetCompanyListingQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';

export const CompanyList = () => {
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const companySearch = JSON.parse(localStorage.getItem('searchFilter'));
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [rerender, setRender] = useState(false);
	const outletID = localStorage.getItem('latestOutlet');
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const { handlePermDisabled } = usePermissionChecker();

	const {
		data: { getCompanyListing } = { getCompanyListing: [] },
		loading: companyLoading,
		called: companyCalled,
		refetch,
	} = useGetCompanyListingQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});
	const history = useHistory();
	// Drawer Toggler
	const handleDrawer = () => {
		localStorage.removeItem('searchFilter');
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};
	useEffect(() => {
		if (getCompanyListing && getCompanyListing?.length > 0 && !companyLoading) {
			setOriginalListing(getCompanyListing);
		}
	}, [getCompanyListing, companyLoading]);
	useEffect(() => {
		if (
			!!companySearch?.outletList &&
			getCompanyListing?.length > 0 &&
			!companyLoading
		) {
			setRender(true);
			handleSearch(`'"${companySearch?.outletList ?? ''}"`, ['name']);
		}
	}, [companyLoading, rerender, getCompanyListing]);
	const onClickAdd = () => {
		history.push(`/menu/outlet-app/common-setting/company-list/add`);
	};

	const callToHandler = (event, phone) => {
		event.stopPropagation();
		// telTo(phone)
	};

	const mailToHandler = (event, email) => {
		event.stopPropagation();
		// mailTo(email)
	};

	const [
		CompanyUpdateStatus,
		{ loading: CompanyUpdateStatusLoading },
	] = useCompanyUpdateStatusMutation({
		onError: error => {
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
		},
		onCompleted: data => {
			if (data.companyUpdateStatus === true) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.updateRecord());
			} else {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.updateRecordFailed());
			}
		},
	});

	const onSubmit = () => {
		CompanyUpdateStatus({
			variables: {
				status: menu?.obj?.recordStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
				companyID: menu?.obj?.id,
			},
		}).then(i => {
			i.data.companyUpdateStatus === true ? refetch() : refetch();
		});
	};

	return (
		<>
			{companyLoading && <Loading />}
			<MainHeader
				onClick={() => history.push(`/menu/outlet-app/common-setting`)}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{
						name: 'Company Listing',
						current: true,
					},
				]}
			/>
			<SearchHeader
				title="Company Listing"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, [
						'name',
						'email',
						'office_tel',
					]);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							companyList: e.target.value ?? '',
							companySubmenu: companySearch?.outletSubmenu ?? '',
							// menuItemSearch: companySearch?.menuItemSearch ?? '',
						}),
					);
				}}
				fixed
				search
				isDefaultValue={!!companySearch?.outletList ?? false}
				defaultValue={companySearch?.outletList ?? ''}
				onCloseAction={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							companyList: '',
							companySubmenu: companySearch?.outletSubmenu ?? '',
							// menuItemSearch: companySearch?.menuItemSearch ?? '',
						}),
					);
					handleSearch('', []);
				}}
				clickText
			/>
			<ContentWrapper float search>
				<List className="core-list">
					{(!companyLoading && getCompanyListing === undefined) ||
					getCompanyListing?.length === 0 ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add an outlet to continue"
						/>
					) : (
						filteredList?.map((el: any, index) => (
							<ListItem
								key={el?.id}
								disabled={el?.recordStatus === 'ACTIVE' ? false : true}
							>
								<ListItemIcon>
									{el?.imageUrl ? (
										<img className="img-owned" src={el?.imageUrl} />
									) : (
										<img
											className="img-owned"
											style={{
												border: 'none',
											}}
											src={NoImage}
										/>
									)}
								</ListItemIcon>
								<ListItemText
									primary={
										<div>
											<span className="xsTitle">{el?.name}</span>
										</div>
									}
									secondary={
										// <Typography>
										// 	<span className="desc">
										// 		<IconButton style={{ padding: '0 4px 4px 0' }}>
										// 			<PhoneIcon
										// 				style={{
										// 					fontSize: '10px',
										// 					color: 'black',
										// 					marginBottom: '-1px',
										// 					marginRight: '-1px',
										// 				}}
										// 			/>
										// 		</IconButton>
										// 		<span
										// 			onClick={e => {
										// 				window.open(`tel: ${el.officeTel}`);
										// 			}}
										// 			className={`primary-click-text`}
										// 			style={{ fontSize: '10px', color: 'black' }}
										// 		>
										// 			{el.officeTel}
										// 		</span>
										// 		<span style={{ margin: '0 5px 0' }}>|</span>
										// 	</span>
										// 	<IconButton style={{ padding: '0 4px 4px 0' }}>
										// 		<EmailIcon
										// 			style={{
										// 				fontSize: '12px',
										// 				color: 'black',
										// 				marginBottom: '-3px',
										// 				marginRight: '3px',
										// 			}}
										// 		/>
										// 	</IconButton>
										// 	<span
										// 		onClick={e => {
										// 			window.open(`mailto: ${el.email}`);
										// 		}}
										// 		className={`primary-click-text`}
										// 		style={{ fontSize: '10px', color: 'black' }}
										// 	>
										// 		{el.email}
										// 	</span>
										// </Typography>
										<>
											<span className="desc">
												<span className="desc">{el?.companyRegNo}</span>
											</span>
										</>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="more"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => handleClick(e, el.ID, index, el)}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() =>
							history.push({
								pathname: `/menu/outlet-app/common-setting/company-list/${menu?.obj?.id}/view`,
								state: {
									company: menu?.obj,
								},
							})
						}
					>
						<span className="">View</span>
					</MenuItem>
					<MenuItem
						onClick={() =>
							history.push({
								pathname: `/menu/outlet-app/common-setting/company-list/${menu?.obj?.id}/edit`,
								state: {
									company: menu?.obj,
								},
							})
						}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							onSubmit();
						}}
					>
						<span className="">
							{menu?.obj?.recordStatus === 'ACTIVE' ? 'Inactive' : 'Active'}
						</span>
					</MenuItem>
				</Menu>
				<FloatButton
					onClick={() =>
						history.push(`/menu/outlet-app/common-setting/company-list/add`)
					}
				/>
			</ContentWrapper>
		</>
	);
};
