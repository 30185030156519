import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	createTheme,
	Grid,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	ThemeProvider,
	useMediaQuery,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Tune } from '@material-ui/icons';
import './consolidatedEInvoice.scss';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import {
	DatePicker,
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	EInvoiceConsolidationTrxType,
	useDocToConsolidateListingLazyQuery,
	useGenerateConsolidatedDocMutation,
	useGetOutletQuery,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import DateFnsUtils from '@date-io/date-fns';
import AppContext from 'containers/App/Store/AppContext';
import theme from '@ifca-root/react-component/src/assets/theme';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import SnackBarContext from 'containers/App/Store/SnackBarContext';

const dateFormat = require('dateformat');

export const ConsolidatedEInvoiceForm = (props: any) => {
	let history = useHistory();
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const { snackFunc, snackBarMessage, openSnackBar, close } = useSnackBar();
	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const { outletID } = useParams<any>();

	const [isExpanded, setIsExpanded] = useState('');
	const [openDialog, setOpenDialog] = useState(false);
	const [isSearch, setIsSearch] = useState(false);
	const savedFilterArgs = JSON.parse(localStorage.getItem('FilterArgs'));
	const [double, setDouble] = useState(false);

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);

	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const initialValue = () => {
		return {
			year: savedFilterArgs?.year ?? new Date().getFullYear(),
			month: savedFilterArgs?.month ?? new Date().getMonth() + 1,
			name: savedFilterArgs?.name ?? null,
			docType: savedFilterArgs?.docType ?? EInvoiceConsolidationTrxType?.Bill,
			docNo: savedFilterArgs?.docNo ?? null,
			startDate: savedFilterArgs?.startDate ?? selectedStartDate,
			endDate: savedFilterArgs?.endDate ?? selectedEndDate,
		};
	};

	const [filterValue, setFilterValue] = useState(initialValue());

	console?.log(savedFilterArgs, filterValue, 'savedFilterArgs');

	const triggerOpenSearchDialog = () => {
		const filterArgs = JSON.parse(localStorage.getItem('FilterArgs'));

		if (JSON.parse(localStorage.getItem('FilterArgs'))) {
			setFilterValue({
				year: filterArgs?.year ?? null,
				month: filterArgs?.month ?? null,
				name: filterArgs?.name || null,
				docType:
					EInvoiceConsolidationTrxType?.Bill ||
					JSON.parse(localStorage.getItem('FilterArgs'))?.docType,
				docNo: filterArgs?.docNo || null,
				startDate: filterArgs?.startDate || null,
				endDate: filterArgs?.endDate || null,
			});
		}
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const onSubmitSearch = () => {
		let filter = {};

		filter['year'] = filterValue?.year !== '' ? filterValue?.year : null;
		filter['month'] = filterValue?.month !== '' ? filterValue?.month : null;
		filter['name'] = filterValue?.name !== '' ? filterValue?.name : null;
		filter['docType'] =
			filterValue?.docType !== ''
				? filterValue?.docType
				: EInvoiceConsolidationTrxType?.Bill;
		filter['docNo'] = filterValue?.docNo !== '' ? filterValue?.docNo : null;
		filter['startDate'] =
			filterValue?.startDate !== '' ? filterValue?.startDate : null;
		filter['endDate'] =
			filterValue?.endDate !== '' ? filterValue?.endDate : null;

		refetch({
			outletID: outletID,
			accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
			year: filter['year'],
			month: filter['month'],
			name: filter['name'],
			docType: filter['docType'],
			docNo: filter['docNo'],
			isSearch: isSearch,
			startDate: selectedStartDate,
			endDate: selectedEndDate,
		});
		localStorage.setItem('FilterArgs', JSON.stringify(filter));
	};

	const [
		LoadDocToConsolidateListing,
		{
			refetch,
			loading: DocToConsolidateListingLoading,
			data: { DocToConsolidateListing } = {
				DocToConsolidateListing: {
					consolidatedEIAll: [],
					consolidatedEI: [],
					consolidatedEICount: 0,
					totalPages: 0,
					currentPage: 0,
					total: {
						baseAmount: 0,
						serviceCharge: 0,
						taxAmount: 0,
						docAmount: 0,
					},
				},
			},
		},
	] = useDocToConsolidateListingLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		LoadDocToConsolidateListing({
			variables: {
				outletID: outletID,
				accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
				year:
					filterValue?.year ||
					JSON.parse(localStorage.getItem('FilterArgs'))?.year,
				month:
					filterValue?.month ||
					JSON.parse(localStorage.getItem('FilterArgs'))?.month,
				name: JSON.parse(localStorage.getItem('FilterArgs'))?.name || '',
				docType:
					filterValue?.docType ||
					JSON.parse(localStorage.getItem('FilterArgs'))?.docType,
				docNo: JSON.parse(localStorage.getItem('FilterArgs'))?.docNo || null,
				isSearch: true,
				limit: 5,
				offset: offset,
				startDate: selectedStartDate,
				endDate: selectedEndDate,
			},
		});
	}, []);

	const handleExpand = panel => (event, newExpanded) => {
		setIsExpanded(newExpanded ? panel : false);
		triggerOpenSearchDialog();
	};

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [offset, setOffset] = React.useState(0);

	const handlePageChange = (event, newPage) => {
		if (newPage > page) {
			setOffset(offset + 5);
		} else if (newPage < page) {
			setOffset(offset - 5);
		}

		setPage(newPage);

		LoadDocToConsolidateListing({
			variables: {
				outletID: outletID,
				accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
				year:
					new Date().getFullYear() ||
					JSON.parse(localStorage.getItem('FilterArgs'))?.year,
				month:
					new Date().getMonth() + 1 ||
					JSON.parse(localStorage.getItem('FilterArgs'))?.month,
				name: JSON.parse(localStorage.getItem('FilterArgs'))?.name || '',
				docType:
					JSON.parse(localStorage.getItem('FilterArgs'))?.docType || null,
				docNo: JSON.parse(localStorage.getItem('FilterArgs'))?.docNo || null,
				isSearch: isSearch,
				limit: 5,
				offset: newPage > page ? offset + 5 : offset - 5,
				startDate: selectedStartDate,
				endDate: selectedEndDate,
			},
		});
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const headCells = [
		{
			ID: 'docDate',
			numeric: false,
			disablePadding: true,
			label: 'Doc Date',
		},
		{
			ID: 'submenu',
			numeric: false,
			disablePadding: true,
			label: '',
		},
		{
			ID: 'docNo',
			numeric: false,
			disablePadding: false,
			label: 'Doc No',
		},
		{
			ID: 'docType',
			numeric: false,
			disablePadding: false,
			label: 'Doc Type',
		},
		{
			ID: 'name',
			numeric: false,
			disablePadding: false,
			label: 'Name',
		},
		{
			ID: 'baseAmount',
			numeric: true,
			disablePadding: false,
			label: 'Base Amt',
		},
		{
			ID: 'serviceCharge',
			numeric: true,
			disablePadding: false,
			label: 'Service Charge',
		},
		{
			ID: 'taxAmount',
			numeric: true,
			disablePadding: false,
			label: 'Tax Amt',
		},
		{
			ID: 'docAmount',
			numeric: true,
			disablePadding: false,
			label: 'Doc Amt',
		},
	];

	const months = [
		{ value: 1, name: 'January' },
		{ value: 2, name: 'February' },
		{ value: 3, name: 'March' },
		{ value: 4, name: 'April' },
		{ value: 5, name: 'May' },
		{ value: 6, name: 'June' },
		{ value: 7, name: 'July' },
		{ value: 8, name: 'August' },
		{ value: 9, name: 'September' },
		{ value: 10, name: 'October' },
		{ value: 11, name: 'November' },
		{ value: 12, name: 'December' },
	];

	const docType = [
		{ value: 1, name: EInvoiceConsolidationTrxType?.Bill, label: 'Bill' },
		{
			value: 2,
			name: EInvoiceConsolidationTrxType?.CreditNote,
			label: 'Credit Note',
		},
	];

	const TableHeader = props => {
		return (
			<>
				<TableHead>
					<TableRow>
						{headCells?.map(el => {
							return (
								<>
									<TableCell
										key={el?.ID}
										align={el?.numeric ? 'right' : 'left'}
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// padding={el?.disablePadding ? 'none' : 'normal'}
										// sortDirection={orderBy === el?.ID ? order : false}
										className="tableHead"
									>
										{/* <TableSortLabel
											active={orderBy === el.ID}
											direction={orderBy === el.ID ? order : 'asc'}
											onClick={createSortHandler(el.ID)}
										> */}
										{el.label}
										{/* </TableSortLabel> */}
									</TableCell>
								</>
							);
						})}
					</TableRow>
				</TableHead>
			</>
		);
	};

	const TableContent = () => {
		return (
			<>
				<TableBody className="table">
					{DocToConsolidateListing?.consolidatedEI.map(row => {
						return (
							<>
								<TableRow
									style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
								>
									<TableCell
										component="th"
										scope="row"
										align="left"
										// className="tableContent"
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
									>
										{dateFormat(row?.docDate, 'dd/mm/yyyy')}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="left"
									>
										{''}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="left"
									>
										{row?.docNo}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="left"
									>
										{row?.docType}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="left"
									>
										{row?.name}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="right"
									>
										{amtStr(row?.baseAmount)}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="right"
									>
										{amtStr(row?.serviceCharge)}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="right"
									>
										{amtStr(row?.taxAmount)}
									</TableCell>
									<TableCell
										style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
										// className="tableContent"
										align="right"
									>
										{amtStr(row?.docAmount)}
									</TableCell>
								</TableRow>
							</>
						);
					})}
				</TableBody>
			</>
		);
	};

	window.onbeforeunload = event => {
		localStorage.removeItem('FilterArgs');
	};

	const [
		GenerateConsolidatedDoc,
		{ loading: GenerateConsolidatedDocLoading },
	] = useGenerateConsolidatedDocMutation({
		onCompleted: () => {
			history.push({
				pathname: `/menu/outlet-app/${outletID}/consolidate-e-inovoice`,
			});
		},
	});

	let amount = 0,
		serviceCharge = 0,
		taxAmt = 0,
		docAmt = 0;
	DocToConsolidateListing?.consolidatedEI?.map(x => {
		amount += x?.baseAmount;
		serviceCharge += x?.serviceCharge;
		taxAmt += x?.taxAmount;
		docAmt += x?.docAmount;
	});

	const [docDate, SetDocDate] = useState(new Date());
	const [description, SetDescription] = useState('');

	const onSubmit = () => {
		if (DocToConsolidateListing?.consolidatedEI?.length === 0) {
			setOpenSnackBar(true);
			setSnackBarMsg('Something went wrong. Please try again');
		} else {
			GenerateConsolidatedDoc({
				variables: {
					outletID: outletID,
					accountID: JSON.parse(localStorage.getItem('loggedInUser'))
						?.accountID,
					companyID: getOutlet[0]?.companyID,
					year:
						filterValue?.year ||
						JSON.parse(localStorage.getItem('FilterArgs'))?.year,
					month:
						filterValue?.month ||
						JSON.parse(localStorage.getItem('FilterArgs'))?.month,
					name: JSON.parse(localStorage.getItem('FilterArgs'))?.name || '',
					docNo: JSON.parse(localStorage.getItem('FilterArgs'))?.docNo || null,
					isSearch: true,
					limit: 5,
					offset: offset,
					trxDate: new Date(),
					docDate: dateFormat(docDate, 'yyyy-mm-dd'),
					docType:
						filterValue?.docType ||
						JSON.parse(localStorage.getItem('FilterArgs'))?.docType,
					description: description,
					startDate: selectedStartDate,
					endDate: selectedEndDate,
				},
			});
		}
	};

	console?.log(selectedStartDate, selectedEndDate, filterValue, 'test');

	return (
		<>
			{DocToConsolidateListingLoading && <Loading />}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/consolidate-e-inovoice`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Consolidated e-Invoice' },
					{ name: 'Consolidated e-Invoice', current: true },
				]}
			/>

			<DynamicSubHeader
				title={
					<span className="display-flex">
						<span className="xsTitle color-primary-orange flex-space">
							Consolidated e-Invoice
						</span>
					</span>
				}
			/>

			<ContentWrapper singleDynamicInfo style={{ marginTop: '30px' }}>
				<Accordion
					expanded={isExpanded === 'panel1'}
					onChange={handleExpand('panel1')}
					className="accordion-consolidatedEI"
				>
					<AccordionSummary
						expandIcon={<Tune />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						style={{ backgroundColor: '#fef1de' }}
					>
						<div className="flex">
							<span
								className="flex-space xsTitle margin-auto"
								style={{ fontSize: '11px', color: '#ff9800' }}
							>
								{`Advance Search`}
							</span>
						</div>
					</AccordionSummary>

					<AccordionDetails>
						{getOutlet[0]?.integrate3rdPartyeInvoice === false ? (
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<ThemeProvider theme={theme}>
									<DatePicker
										className="left advanceSearch"
										style={{ width: '48%' }}
										InputLabelProps={{ style: { fontSize: '11px' } }}
										views={['year']}
										label="Year"
										value={new Date(filterValue?.year, 0, 1)}
										onChange={e => {
											setIsSearch(true);
											setFilterValue({
												...filterValue,
												year: e.getFullYear(),
											});
										}}
										autoOk
										disableFuture
										disableToolbar
										format="yyyy"
									/>

									<DatePicker
										className="right advanceSearch"
										style={{ width: '48%', marginLeft: '10px' }}
										InputLabelProps={{ style: { fontSize: '11px' } }}
										views={['month']}
										label="Month"
										value={new Date(2024, filterValue.month - 1, 1)}
										onChange={e => {
											setIsSearch(true);
											setFilterValue({
												...filterValue,
												month: e.getMonth() + 1,
											});
										}}
										autoOk
										disableToolbar
										format="MMMM"
									/>
								</ThemeProvider>
							</MuiPickersUtilsProvider>
						) : (
							<Grid container direction="row">
								<Grid item xs={5} container justify="center">
									<KeyboardDatePicker
										inputVariant="outlined"
										// format="EEE, dd MMM yyyy"
										format="dd MMM yyyy"
										id="date-picker-dialog"
										value={selectedStartDate}
										autoOk
										onChange={handleStartDateChange}
										InputProps={{
											style: {
												fontSize: '1rem',
												backgroundColor: 'white',
												color: 'black',
											},
											readOnly: true,
											disableUnderline: true,
											fullWidth: true,
										}}
										KeyboardButtonProps={{
											style: {},
											'aria-label': 'change date',
										}}
										style={{
											zIndex: 40,
											width: '100%',
										}}
										className="custom-date-bi"
									/>
								</Grid>
								<Grid item xs={2} container justify="center">
									<div
										style={{
											marginTop: '8px',
											// paddingLeft: isDesktop && globalState.drawerOpen ? 310 : 0,
										}}
									>
										{'>'}
									</div>
								</Grid>
								<Grid item xs={5} container justify="center">
									<KeyboardDatePicker
										inputVariant="outlined"
										// format="EEE, dd MMM yyyy"
										format="dd MMM yyyy"
										id="date-picker-dialog"
										value={selectedEndDate}
										onChange={handleEndDateChange}
										autoOk
										InputProps={{
											style: {
												fontSize: '1rem',
												backgroundColor: 'white',
												color: 'black',
											},
											readOnly: true,
											disableUnderline: true,
											fullWidth: true,
										}}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										style={{
											zIndex: 40,
											width: '100%',
										}}
										className="custom-date-bi"
										fullWidth={true}
									/>
								</Grid>
							</Grid>
						)}

						<TextField
							className="advanceSearch"
							fullWidth
							style={{ width: '97%' }}
							label="Name"
							InputLabelProps={{ style: { fontSize: '11px' } }}
							value={filterValue?.name || ''}
							onChange={e =>
								setFilterValue({
									...filterValue,
									name: e.target.value,
								})
							}
						/>

						<TextField
							className="left advanceSearch"
							style={{ width: '48%' }}
							// fullWidth
							select
							label="Doc Type"
							InputLabelProps={{ style: { fontSize: '11px' } }}
							value={filterValue?.docType || EInvoiceConsolidationTrxType?.Bill}
							onChange={e => {
								setIsSearch(true);
								setFilterValue({
									...filterValue,
									docType: e.target.value,
								});
							}}
						>
							{docType?.map(el => (
								<MenuItem key={el?.value} value={el?.name}>
									{el?.label}
								</MenuItem>
							))}
						</TextField>

						<TextField
							className="right advanceSearch"
							// fullWidth
							style={{ width: '48%', marginLeft: '10px' }}
							label="Doc No"
							InputLabelProps={{ style: { fontSize: '11px' } }}
							value={filterValue?.docNo || ''}
							onChange={e => {
								setIsSearch(true);
								setFilterValue({
									...filterValue,
									docNo: e.target.value,
								});
							}}
						/>

						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								gap: '10px',
								marginTop: '10px',
								width: '100%',
							}}
						>
							<Button
								variant="contained"
								color="secondary"
								style={{ fontSize: '11px' }}
								onClick={() => {
									localStorage.removeItem('FilterArgs');
									const resetValue = initialValue();
									setFilterValue(resetValue);
									setIsExpanded('');
								}}
							>
								Reset
							</Button>
							<Button
								variant="contained"
								color="primary"
								className=""
								style={{
									fontSize: '11px',
									backgroundColor: 'orange',
								}}
								onClick={() => {
									onSubmitSearch();
									setIsExpanded('');
									setIsSearch(true);
								}}
							>
								Filter
							</Button>
						</div>
					</AccordionDetails>
				</Accordion>
			</ContentWrapper>

			<ContentWrapper footer style={{ marginTop: '0px' }}>
				<div style={{ width: '100%' }}>
					<Paper style={{ width: '100%' }}>
						<TableContainer>
							<Table
								style={{
									minWidth: 750,
									fontSize: '11px',
									padding: '5px 10px 5px 10px',
								}}
							>
								<TableHeader />

								{!DocToConsolidateListingLoading && <TableContent />}

								<TableBody>
									<TableRow>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
										>{`Total record: ${DocToConsolidateListing?.consolidatedEICount}`}</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
										>
											{''}
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
										>
											{''}
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
										>
											{''}
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
										>
											Total
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
											align="right"
										>
											{amtStr(DocToConsolidateListing?.total?.baseAmount)}
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
											align="right"
										>
											{amtStr(DocToConsolidateListing?.total?.serviceCharge)}
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
											align="right"
										>
											{amtStr(DocToConsolidateListing?.total?.taxAmount)}
										</TableCell>
										<TableCell
											style={{ fontSize: '11px', padding: `5px 10px 5px 10px` }}
											className="tableHead"
											align="right"
										>
											{amtStr(DocToConsolidateListing?.total?.docAmount)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>

						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 50, 100]}
							component="div"
							count={DocToConsolidateListing?.consolidatedEICount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</div>
			</ContentWrapper>

			<Footer
				overlap
				style={{
					width:
						isDesktop && globalState.drawerOpen
							? 'calc(100% - 220px)'
							: isDesktop && !globalState.drawerOpen
							? 'calc(100% - 44px)'
							: '100%',
				}}
				options={[
					{
						name: 'Send to LHDN',
						onClick: () => {
							setOpenDialog(true);
							setDouble(false);
						},
						props: {
							type: 'submit',
						},

						color: 'primary',
						disabled: false,
					},
				]}
			/>

			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="xsTitle color-primary-orange flex-space">
												Consolidated e-Invoice
											</div>
										</div>
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							<CardContents>
								<span className="content-wrap full">
									<span className="mdDesc">
										{`Invoice (${DocToConsolidateListing?.consolidatedEICount})`}
									</span>
									<span
										className="mdDesc"
										style={{ float: 'right', marginTop: '7px' }}
									>
										{amtStr(DocToConsolidateListing?.total?.docAmount)}
									</span>
								</span>
								<KeyboardDatePicker
									value={new Date()}
									margin="normal"
									autoOk
									label="Document Date"
									required
									onChange={date => {
										SetDocDate(date);
									}}
									format="dd/MM/yyyy"
								/>
								<TextField
									fullWidth
									multiline
									label="Description"
									onChange={e => {
										SetDescription(e.target.value);
									}}
								/>
							</CardContents>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Proceed',
								props: {
									onClick: () => {
										onSubmit();
										setDouble(true);
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
									disabled: double,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
